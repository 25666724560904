import { v4 as uuidv4 } from 'uuid';

export const clone = (content) => {
    // SET NEW UUID
    const newContent = { ..._.cloneDeep(content), uuid: uuidv4() };

    return newContent;
}

export default {};
