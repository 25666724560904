import React from 'react';
import "../index.scss";
import { v4 as uuidv4 } from 'uuid';

const name = 'Grid 2 col';

export default () => {
    return {
        type: 'row',
        group: 'block',
        image: '/libs/builder/icons/grid_row.svg',
        blockSize: "col-12",
        controlContent: () => {
            const elements = [1, 2];
            return (
                <React.Fragment>
                    <div className='row row_control__wrapper'>
                        {elements.map((value, index) => {
                            return (
                                <div className='col-6 row_control__wrapper__col'>
                                    <div className='row row_control__wrapper__col__content' />
                                </div>
                            )
                        })}
                    </div>
                    <div className='row row_control__label'>
                        {name}
                    </div>
                </React.Fragment >
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": 'block',
            "type": "row",
            "class": "row",
            "style": {
            },
            "colomns": [
                {
                    "uuid": uuidv4(),
                    "type": "two-columns",
                    "class": "col-6",
                    "attributes": {
                        "col": 6
                    },
                    "style": {
                    },
                    "contents": []
                },
                {
                    "uuid": uuidv4(),
                    "type": "two-columns",
                    "class": "col-6",
                    "attributes": {
                        "col": 6
                    },
                    "style": {
                    },
                    "contents": []
                }
            ]
        }
    };
};
