import React, { useState } from 'react';
import Cropper from 'react-cropper';
import Modal from "@components/modal";
import './index.css';
import { useTranslation } from 'react-i18next';

const ImageCropper = ({ image, imageType, visible, hide, setImage, ratio }) => {
  const [cropper, setCropper] = useState();
  const { t } = useTranslation('editor');

  const getCropData = () => {
    if (cropper) {
      return cropper?.getCroppedCanvas().toDataURL(imageType);
    }
  };

  return (
    <Modal visible={visible} hide={hide}>
      <div className="cropper-wrapper">
        <Cropper
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={ratio}
          aspectRatio={ratio}
          preview=".img-preview"
          src={image}
          viewMode={2}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          cropBoxResizable
          onInitialized={instance => {
            setCropper(instance);
          }}
        />
        <div className="row mt-3">
          <button
            className="btn"
            onClick={e => {
              e.preventDefault();
              hide();
            }}
          >
            {t("globals.cancel")}
          </button>
          <button
            className="btn btn-primary"
            onClick={async (e) => {
              e.preventDefault();
              const result = await setImage(getCropData());
              if (result)
                hide();
            }}
          >
            {t("globals.validate")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ImageCropper.defaultProps = {
  image: null,
  ratio: undefined,
  imageType: undefined,
  setImage: () => undefined,
  hide: () => undefined,
};

export default ImageCropper;
