import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import ColorPicker from '@views/editor/components/right-panel/style-tab/properties/color-picker/ColorPicker';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function Button({ component }) {
    const { t } = useTranslation('editor');

    const _linkActions = [
        { value: "webpage", label: <div> <i class="la la-globe" /> {t('right-panel.button.link-action-webpage')}</div> },
        { value: "email", label: <div><i class="la la-at" /> {t('right-panel.button.link-action-email')}</div> }
    ]

    const { page, selectedElement, updatePage } = useEditor();

    const [linkAction, setLinkAction] = useState(null);
    const [webPageLink, setWebPageLink] = useState(null);
    const [emailToLink, setEmailToLink] = useState(null);
    const [emailSubjectLink, setEmailSubjectLink] = useState(null);
    const [emailBodyLink, setEmailBodyLink] = useState(null);

    const [textAlign, setTextAlign] = useState(null);

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const [spaceTop, setSpaceTop] = useState(null);
    const [spaceBottom, setSpaceBottom] = useState(null);
    const [spaceLeft, setSpaceLeft] = useState(null);
    const [spaceRight, setSpaceRight] = useState(null);

    const [borderRadius, setBorderRadius] = useState(null);

    const [backgroundColor, setBackgroundColor] = useState(null);

    const [borderColor, setBorderColor] = useState(null);

    const [borderWidth, setBorderWidth] = useState(null);
    const [borderStyle, setBorderStyle] = useState(null);

    const [hide, setHide] = useState();

    useEffect(() => {
        init();
    }, [selectedElement]);

    useEffect(() => {
        init()
    }, []);

    const init = () => {

        /********** Link Params **********/
        // Init Link Params
        setWebPageLink("");
        setEmailToLink("");
        setEmailSubjectLink("");
        setEmailBodyLink("");

        if (selectedElement?.link) {
            if (selectedElement?.link?.startsWith("mailto:")) {
                // MAIL
                const SUBJECT_TAG_FILTER = "?subject=";
                const BODY_TAG_FILTER = "&body=";

                setLinkAction(_linkActions[1]);
                setEmailToLink(selectedElement.link.slice(7, ((selectedElement.link.indexOf(SUBJECT_TAG_FILTER) > 0) ? selectedElement.link.indexOf(SUBJECT_TAG_FILTER) : selectedElement.link.length)));

                if (selectedElement.link.indexOf(SUBJECT_TAG_FILTER) > 0) {
                    setEmailSubjectLink(selectedElement.link.slice(
                        selectedElement.link.indexOf(SUBJECT_TAG_FILTER) + SUBJECT_TAG_FILTER.length,
                        (selectedElement.link.indexOf(BODY_TAG_FILTER) > 0) ? selectedElement.link.indexOf(BODY_TAG_FILTER) : selectedElement.link.length
                    ));
                }

                if (selectedElement.link.indexOf(BODY_TAG_FILTER) > 0) {
                    let _body = selectedElement.link.slice(
                        selectedElement.link.indexOf(BODY_TAG_FILTER) + BODY_TAG_FILTER.length, selectedElement.link.length);
                    _body = decodeURIComponent(_body);
                    setEmailBodyLink(_body);
                }

            } else {
                // WEBPAGE
                setLinkAction(_linkActions[0]);
                setWebPageLink(selectedElement?.link);
            }
        } else {
            // WEBPAGE
            setLinkAction(_linkActions[0]);
            setWebPageLink("");
        }
        /********** END Link Params **********/

        setTextAlign(selectedElement.style.textAlign);

        setWidth(selectedElement.style.width);
        setHeight(selectedElement.style.height);

        setSpaceTop((selectedElement?.style?.paddingTop) ? selectedElement.style.paddingTop : "0px");
        setSpaceBottom((selectedElement?.style?.paddingBottom) ? selectedElement.style.paddingBottom : "0px");
        setSpaceLeft((selectedElement?.style?.paddingLeft) ? selectedElement.style.paddingLeft : "0px");
        setSpaceRight((selectedElement?.style?.paddingRight) ? selectedElement.style.paddingRight : "0px");

        setBorderRadius(selectedElement.style.borderRadius);
        setBorderRadius((selectedElement?.style?.borderRadius) ? selectedElement.style.borderRadius : "0px");

        setBackgroundColor(selectedElement.style.backgroundColor);
        setBorderColor(selectedElement.style.borderColor);

        setBorderWidth(selectedElement.style.borderWidth);
        setBorderStyle(selectedElement.style.borderStyle);

    };

    const onChangeBorderColor = (comp, value) => {
        let _val = (value) ? value : "#fff"; // <=== default value
        setBorderColor(value);
        selectedElement.style = {
            ...selectedElement.style,
            borderColor: _val
        }
        updatePage(page, true);
    };

    const onChangeLinkAction = (option) => {
        setLinkAction(option);
        switch (option.value) {
            case "email":
                selectedElement.link = getFormatedEmailLink(emailToLink, emailSubjectLink, emailBodyLink);;
                break;
            default:
                selectedElement.link = "";
        }
    };

    const onChangeEmailToLink = (ev) => {
        setEmailToLink(ev.target.value);
        selectedElement.link = getFormatedEmailLink(ev.target.value, emailSubjectLink, emailBodyLink);
    };

    const onChangeEmailSubjectLink = (ev) => {
        setEmailSubjectLink(ev.target.value);
        selectedElement.link = getFormatedEmailLink(emailToLink, ev.target.value, emailBodyLink);
    };

    const onChangeBodyLink = (ev) => {
        setEmailBodyLink(ev.target.value);
        selectedElement.link = getFormatedEmailLink(emailToLink, emailSubjectLink, encodeURIComponent(ev.target.value));
    };

    const onChangeBackgroundColor = (comp, value) => {
        let _val = (value) ? value : "#fff"; // <=== default value
        setBackgroundColor(value);
        selectedElement.style = {
            ...selectedElement.style,
            backgroundColor: _val
        }
        updatePage(page, true)
    };

    const getFormatedEmailLink = (to, subject, body) => {
        return `mailto:${(to) ? to : ""}?subject=${(subject) ? subject : ""}&body=${(body) ? body : ""}`
    }

    const onChangeTextAlign = (component, value) => {
        setTextAlign(value);
        if (value != 'none') {
            selectedElement.style = { ...selectedElement.style, textAlign: value };
        } else {
            selectedElement.style = { ...selectedElement.style, textAlign: '' };
        }
    };

    const onChangeWebPage = (ev) => {
        setWebPageLink(ev.target.value);
        selectedElement.webPageLink = ev.target.value;
        selectedElement.link = ev.target.value;
    };

    return (
        <React.Fragment>
            <PropertyWrapper
                label={t('right-panel.button.link-property')}>
                <div key={`button-link-${selectedElement.uuid}`} className="mb-12 col-sm-12">
                    <div className="row mb-12">
                        <div className="link_property_label col-sm-6">
                            {t('right-panel.button.redirection-link-label')}
                        </div>
                        <div className="col-sm-6">
                            <Select
                                value={linkAction}
                                onChange={onChangeLinkAction}
                                // menuPlacement="auto"
                                options={_linkActions}
                            />
                        </div>
                    </div>
                    {(linkAction?.value === "webpage") && (
                        <React.Fragment>
                            <div key={`button-link-url-${selectedElement.uuid}`} class="input-group">
                                <div class="input-group-append">
                                    <div className="link_subproperty_label">{t('right-panel.button.link-webpage-url')}</div>
                                </div>
                                <input className="link" type="text" class="form-control" value={webPageLink} onChange={onChangeWebPage} />
                            </div>
                        </React.Fragment>
                    )}
                    {(linkAction?.value === "email") && (
                        <React.Fragment>
                            <div className="input-group mb-6">
                                <div className="input-group-append">
                                    <div className="link_subproperty_label">{t('right-panel.button.link-email-to')}</div>
                                </div>
                                <input className="link" type="text" class="form-control" value={emailToLink} onChange={onChangeEmailToLink} />
                            </div>
                            <div className="input-group mb-6">
                                <div className="input-group-append">
                                    <div className="link_subproperty_label">{t('right-panel.button.link-email-subject')}</div>
                                </div>
                                <input className="link" type="text" class="form-control" value={emailSubjectLink} onChange={onChangeEmailSubjectLink} />
                            </div>
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="link_subproperty_label" style={{height: "100%", display: "flex", alignItems: "center"}}>{t('right-panel.button.link-email-body')}</div>
                                </div>
                                <textarea className="link" class="form-control" value={emailBodyLink} onChange={onChangeBodyLink} />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.size-property')}>
                <div key={`button-width-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-6">
                    <Size label={"Width"} property={"width"} value={width} component={component} />
                </div>
                <div key={`button-height-${selectedElement.uuid}`} className="mb-3 p-4  col-sm-6">
                    <Size label={"Height"} property={"height"} value={height} component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.alignement-property')}>
                <div key={`button-algnement-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12" data-key="text-align">
                    {/* <label class=" form-label" for="input-model">Alignement</label> */}
                    <div class=" input">
                        <div class="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">

                            <TextAlign
                                val={textAlign}
                                component={component}
                                onChange={onChangeTextAlign}
                                hiddenOptions={["", "justify"]}
                            />

                        </div>
                    </div>
                </div>

            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.spacing-property')}>

                <div key={`button-spacing-top-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Top"} property={"paddingTop"} value={spaceTop} component={component} />
                </div>
                <div key={`button-spacing-bottom-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom"} property={"paddingBottom"} value={spaceBottom} component={component} />
                </div>
                <div key={`button-spacing-left-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Left"} property={"paddingLeft"} value={spaceLeft} component={component} />
                </div>
                <div key={`button-spacing-right-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Right"} property={"paddingRight"} value={spaceRight} component={component} />
                </div>
            </PropertyWrapper>


            <PropertyWrapper
                label={t('globals.style-property')}>
                <div key={`button-bg-color-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-12 row">
                    <div className="col-sm-12">
                        <label className="form-label" for="input-model">{t("right-panel.button.background-color-label")}</label>
                        <ColorPicker
                            color={backgroundColor}
                            component={component}
                            onChange={onChangeBackgroundColor}
                        />
                    </div>
                </div>
                <div key={`button-border-radus-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-12">
                    <Size label={t('right-panel.button.border-radius-label')} property={"borderRadius"} value={borderRadius} component={component} />
                </div>

                {/* BORDER */}
                <div key={`button-border-width-${selectedElement.uuid}`} className="mb-3 p-4  col-sm-6">
                    <Size label={t('right-panel.button.border-width-label')} property={"borderWidth"} value={borderWidth} component={component} />
                </div>
                <div class="mb-3 p-4  col-sm-6">
                    <label className="form-label" for="input-model">{t('right-panel.button.border-color-label')}</label>
                    <ColorPicker
                        color={borderColor}
                        component={component}
                        onChange={onChangeBorderColor}
                    />
                </div>

                <div key={`button-border-style-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.button.border-style-label')}</label>
                    <select class="form-select small-arrow" name="borderStyle" value={borderStyle}
                        onChange={(ev) => {
                            setBorderStyle(ev.target.value);
                            component.style = {
                                ...component.style,
                                borderStyle: ev.target.value
                            };
                            updatePage(page, true);
                        }}>
                        <option value="none">none</option>
                        <option value="solid">solid</option>
                        <option value="dashed">dashed</option>
                    </select>
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('globals.hide-device-label')} </label><br />
                    <Hide
                        component={component}
                    />
                </div>
            </PropertyWrapper>

        </React.Fragment>
    );
}

export default Button;
