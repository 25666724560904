import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import FormRange from '@views/editor/components/right-panel/style-tab/properties/form-range/FormRange';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import SocialMediaStyle from './SocialMediaStyle';
import { useTranslation } from 'react-i18next';

export const onChangeTextAlign = (component, value) => {
    if (value != 'none') {
        component.style = { ...component.style, textAlign: value };
    } else {
        component.style = { ...component.style, textAlign: '' };
    }
};

function SocialMedia({ colomn, component }): React.Node {

    const { selectedElement } = useEditor();
    const { t } = useTranslation('editor');

    const [textAlign, setTextAlign] = useState(null);

    const [textColor, setTextColor] = useState(null);

    const [spaceTop, setSpaceTop] = useState(null);
    const [spaceBottom, setSpaceBottom] = useState(null);
    const [spaceLeft, setSpaceLeft] = useState(null);
    const [spaceRight, setSpaceRight] = useState(null);

    const [iconesSpacing, setIconesSpacing] = useState(0);
    const [width, setWidth] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);


    useEffect(() => {
        init();
    }, []);

    const init = () => {

        setTextAlign(selectedElement.style.textAlign);

        setSpaceTop(selectedElement.style.paddingTop);
        setSpaceBottom(selectedElement.style.paddingBottom);
        setSpaceLeft(selectedElement.style.paddingLeft);
        setSpaceRight(selectedElement.style.paddingRight);

        setIconesSpacing(selectedElement.attributes.iconesSpacing);
        setWidth(selectedElement.attributes.width);

    };

    const onChangeTextAlign = (component, value) => {
        setTextAlign(value);
        if (value != 'none') {
            component.style = { ...component.style, textAlign: value };
        } else {
            component.style = { ...component.style, textAlign: '' };
        }
    }

    const onChangeIconsSpacing = (component, value) => {
        selectedElement.attributes = { ...selectedElement.attributes, iconesSpacing: value };
        setIconesSpacing(value);
    }

    const onChangeIconsWidth = (component, value) => {
        selectedElement.attributes = { ...selectedElement.attributes, width: value };
        setWidth(value);
    }

    return (
        <React.Fragment>
            <PropertyWrapper
                label={"Social Media"}>
                <div key={`sm-style-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <SocialMediaStyle
                        component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t("right-panel.social-media.icon-property")}>
                <div key={`sm-width-${selectedElement.uuid}`} class="mb-3 p-4  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.social-media.icon-size-label')} : {width}px</label>
                    <div class="input">
                        <div class="input-range">
                            <FormRange
                                val={width}
                                max={150}
                                step={1}
                                onChange={onChangeIconsWidth}
                            />
                        </div>
                    </div>
                </div>
                <div key={`sm-icon-spacing-${selectedElement.uuid}`} class="mb-3 p-4  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.social-media.icon-spacing-label')} : {iconesSpacing}px</label>
                    <div class=" input">
                        <div class="input-range">
                            <FormRange
                                val={iconesSpacing}
                                min={2.5}
                                max={10}
                                step={0.5}
                                onChange={onChangeIconsSpacing}
                            />
                        </div>
                    </div>
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.alignement-property')}>
                <div key={`sm-align-${selectedElement.uuid}`} class="mb-3  col-sm-12" data-key="text-align">
                    <div class=" input">
                        <div class="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">

                            <TextAlign
                                val={textAlign}
                                component={component}
                                onChange={onChangeTextAlign}
                                hiddenOptions={["", "justify"]}
                            />

                        </div>
                    </div>
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.spacing-property')}>
                <div key={`sm-spacing-top-${selectedElement.uuid}`} class="mb-3 p-4  col-sm-6">
                    <Size label={"Top"} property={"paddingTop"} value={spaceTop} component={component} />
                </div>
                <div key={`sm-spacing-bottom-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom"} property={"paddingBottom"} value={spaceBottom} component={component} />
                </div>
                <div key={`sm-spacing-left-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Left"} property={"paddingLeft"} value={spaceLeft} component={component} />
                </div>
                <div key={`sm-spacing-right-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Right"} property={"paddingRight"} value={spaceRight} component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('globals.hide-device-label')}</label><br />
                    <Hide
                        component={component}
                    />
                </div>
            </PropertyWrapper>
        </React.Fragment >
    );
}

export default SocialMedia;
