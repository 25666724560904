import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { pick } from 'lodash';
import "./index.scss"

function Text({ colomn, content }) {

  const [width, setWidh] = useState(content.attributes?.width)
  const ref = useRef()

  return (
    <React.Fragment>
      <div 
        key={content.uuid}
        ref={ref}
        className={clsx('socialMedia_container', content.class)}
        style={{ ...pick(content.style, ["textAlign", "paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]) }}
      >
        {(content.socialMedia && content.socialMedia.length > 0) && content.socialMedia?.map((sm, index) => {
          return (
            <span
              className={clsx('socialMedia_container_content', sm.class)} >
              <a href={"#"}
                style={{
                  paddingLeft: (content.attributes?.iconesSpacing && index > 0) ? content.attributes.iconesSpacing+"px" : "0px" ,
                  paddingRight: (content.attributes?.iconesSpacing && index < content.socialMedia.length) ? content.attributes.iconesSpacing+"px" : "0px"
                }}
                >
                <img
                  width={(content.attributes?.width) ? content.attributes.width : 25}
                  src={sm.imgSrc}
                  alt={sm.alt} />
              </a>
            </span>
          )
        })}

      </div>
    </React.Fragment>
  );
}

export default Text;
