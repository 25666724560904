import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import FormRange from '@views/editor/components/right-panel/style-tab/properties/form-range/FormRange';
import ColorPicker from '@views/editor/components/right-panel/style-tab/properties/color-picker/ColorPicker';
import { useTranslation } from 'react-i18next';

function Body({ component }): React.Node {


    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [subject, setSubject] = useState(null);
    const [preview, setPreview] = useState(null);
    const [textAlign, setTextAlign] = useState(null);
    const [maxWidth, setMaxWidth] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);

    useEffect(() => {
        init()
    }, []);


    const init = () => {
        setSubject(page.subject);
        setPreview(page.preview);
        setTextAlign(page.global?.row?.style.textAlign);
        setMaxWidth((page.global?.row?.style?.maxWidth) ? page.global.row.style.maxWidth.slice(0, page.global.row.style.maxWidth.length - 2) : 800);
        setBackgroundColor(page.body.style?.backgroundColor);
    };

    const onChangeTextAlign = (component, value) => {
        setTextAlign(value);
        let _val = (value && value != 'none') ? value : "center";
        component.style = { ...component.style, textAlign: _val };
        page.global.row.style = { ...page.global.row.style, textAlign: _val };
        page.global.row.attributes = { ...page.global.row.attributes, align: _val };
    };

    const onChangeMaxWidth = (component, value) => {
        let _val = (value && value != 'none') ? value : "center";
        setMaxWidth(_val)
        component.style = { ...component.style, maxWidth: _val + "px" };
        page.global.row.style = { ...page.global.row.style, maxWidth: _val + "px" };
    };

    const onChangeBackgroundColor = (component, value) => {
        let _val = (value) ? value : undefined; // <=== default value
        component.style = { ...component.style, backgroundColor: _val };
        // page.global.row.style = { ...page.global.row.style, backgroundColor: _val };   // CHANGED BY MED TO HANDEL BACKGROUND : LINEAR-GRADIENT
        setBackgroundColor(_val)
    };

    const onChangeSubject = (ev) => {
        let _val = ev.target.value;
        page.subject = _val;
        setSubject(_val)
    };

    const onChangePreview = (ev) => {
        let _val = ev.target.value;
        page.preview = _val;
        setPreview(_val)
    };

    return (
        <React.Fragment>

            <PropertyWrapper
                label={t('right-panel.body.subject-property')}>
                <div class="mb-12  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.body.email-subject-label')}</label>
                    <input name="subject" type="text" class="form-control" value={subject} onChange={onChangeSubject} />
                </div>

                <div class="mb-12  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.body.email-preview-label')}</label>
                    <input name="preview" type="text" class="form-control" value={preview} onChange={onChangePreview} />
                </div>

            </PropertyWrapper>

            <PropertyWrapper
                label={t('right-panel.body.body-property')}>
                <div class="mb-12  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.body.dimension-label')} : {maxWidth}px</label>
                    <div class=" input">
                        <div class="input-range">
                            <FormRange
                                min={320}
                                max={800}
                                step={5}
                                val={maxWidth}
                                onChange={onChangeMaxWidth}
                                component={component}
                            />
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.body.alignement-label')}</label>
                    <div class=" input">
                        <div class="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">
                            <TextAlign
                                val={textAlign}
                                component={component}
                                onChange={onChangeTextAlign}
                                hiddenOptions={["", "justify"]}
                            />

                        </div>
                    </div>
                </div>

            </PropertyWrapper>

            <PropertyWrapper
                label={t('right-panel.body.color-property')}>
                <div class="mb-12  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.body.background-color-label')}</label>
                    <ColorPicker
                        color={backgroundColor}
                        component={component}
                        onChange={onChangeBackgroundColor}
                    />
                </div>
            </PropertyWrapper>
        </React.Fragment>
    );
}

export default Body;
