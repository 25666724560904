import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import FormRange from '@views/editor/components/right-panel/style-tab/properties/form-range/FormRange';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import ImagePicker from "@views/editor/components/image-picker";
import { useTranslation } from 'react-i18next';


function Image({ component }): React.Node {

    const { page, selectedElement, selectedTarget, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [textAlign, setTextAlign] = useState(null);

    const [src, setSrc] = useState(null);

    const [width, setWidth] = useState(null);
    const [widthPercent, setWidthPercent] = useState(null);

    const [spaceTop, setSpaceTop] = useState(null);
    const [spaceBottom, setSpaceBottom] = useState(null);
    const [spaceLeft, setSpaceLeft] = useState(null);
    const [spaceRight, setSpaceRight] = useState(null);

    const [showImagePickerModal, setShowImagePickerModal] = useState(false);
    const [link, setLink] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);


    useEffect(() => {
        init();
    }, []);

    const init = () => {
        setTextAlign(selectedElement?.style?.textAlign);

        let width = (selectedElement?.attributes?.width) ? selectedElement.attributes.width : getMaxWidth();

        setSrc((selectedElement.src) ? selectedElement.src : "");
        setLink((selectedElement.link) ? selectedElement.link : null);

        setWidth(Math.round(width));
        setWidthPercent(width * 100 / getMaxWidth());

        setSpaceTop((selectedElement?.style?.paddingTop) ? selectedElement.style.paddingTop : "0px");
        setSpaceBottom((selectedElement?.style?.paddingBottom) ? selectedElement.style.paddingBottom : "0px");
        setSpaceLeft((selectedElement?.style?.paddingLeft) ? selectedElement.style.paddingLeft : "0px");
        setSpaceRight((selectedElement?.style?.paddingRight) ? selectedElement.style.paddingRight : "0px");

    };

    const handelOnChange = (comp, value) => {

        setWidthPercent(value);

        let maxWidth = getMaxWidth();

        if (maxWidth) {
            let width = Math.round(value * maxWidth / 100);
            setWidth(width);
            selectedElement.attributes = { ...component.attributes, width };
            updatePage(page, true);
        }
    };

    const getMaxWidth = () => {
        return selectedTarget.closest(".col") ? selectedTarget.closest(".col").offsetWidth : undefined;
    };

    const onChangeSrc = (ev) => {
        setSrc(ev.target.value);
        selectedElement.src = ev.target.value;
        updatePage(page, true);
    };

    const onChangeTextAlign = (component, value) => {
        setTextAlign(value);
        if (value != 'none') {
            component.style = { ...component.style, textAlign: value };
        } else {
            component.style = { ...component.style, textAlign: '' };
        }
    }

    const onChangeLink = (ev) => {
        setLink(ev.target.value);
        selectedElement.link = ev.target.value;
        updatePage(page, true);
    };

    return (
        <React.Fragment>
            <PropertyWrapper
                label={t("right-panel.image.link-property")}>
                <div key={`image-link-${selectedElement.uuid}`} class="mb-12  col-sm-12">
                    <label class="form-label" for="input-model">{t("right-panel.image.redirection-link-label")} </label>
                    <input name="link" type="text" class="form-control" value={link} onChange={onChangeLink} />
                </div>
            </PropertyWrapper>
            <PropertyWrapper
                label={t("right-panel.image.image-property")}>
                <div key={`image-src-${selectedElement.uuid}`} className="mb-12 col-sm-12">
                    <label class="form-label" for="input-model">{t("right-panel.image.src")} </label>
                    <input name="src" type="text" className="form-control" value={src} onChange={onChangeSrc} />
                    <ImagePicker
                        visible={showImagePickerModal}
                        hide={() => setShowImagePickerModal(false)}
                        selectedImages={[src]}
                        onPickImage={(selectedImage) => {
                            setSrc(selectedImage);
                            selectedElement.src = selectedImage;
                            updatePage(page, true);
                            return true;
                        }}
                    />

                    <button className="btn btn-primary mb-5 mt-5" onClick={() => setShowImagePickerModal(true)}>
                        {t("right-panel.image.change-image")}
                    </button>

                </div>
            </PropertyWrapper>

            <PropertyWrapper 
                label={t("right-panel.image.dimension-label")}>
                <div className="mb-12 col-sm-12">
                    <label className=" form-label" for="input-model">{t('globals.size-property')} : {width}px</label>
                    <div className=" input">
                        <div className="input-range">
                            <FormRange
                                val={widthPercent}
                                max={100}
                                onChange={handelOnChange}
                            />
                        </div>
                    </div>
                </div>
            </PropertyWrapper>

            <PropertyWrapper 
                label={t("globals.alignement-property")}>
                <div className="mb-3  col-sm-12" data-key="text-align">
                    <div className="input">
                        <div className="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">
                            <TextAlign
                                val={textAlign}
                                component={component}
                                onChange={onChangeTextAlign}
                                hiddenOptions={["", "justify"]}
                            />
                        </div>
                    </div>
                </div>
            </PropertyWrapper>

            <PropertyWrapper 
                label={t("globals.spacing-property")}>
                <div className="mb-3 p-4  col-sm-6">
                    <Size label={"Top"} property={"paddingTop"} value={spaceTop} component={component} />
                </div>
                <div className="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom"} property={"paddingBottom"} value={spaceBottom} component={component} />
                </div>
                <div className="mb-3 p-4 col-sm-6">
                    <Size label={"Left"} property={"paddingLeft"} value={spaceLeft} component={component} />
                </div>
                <div className="mb-3 p-4 col-sm-6">
                    <Size label={"Right"} property={"paddingRight"} value={spaceRight} component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div className="mb-3 p-4 col-sm-12">
                    <label className="form-label" for="input-model">{t('globals.hide-device-label')}</label><br />
                    <Hide component={component} />
                </div>
            </PropertyWrapper>
        </React.Fragment >
    );
}

export default Image;
