import React from 'react';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import { useTranslation } from 'react-i18next';

function DynamicContent({ component }) {
  const { t } = useTranslation('editor');

  console.log(`DynamicContent`, component);
  return (
    <React.Fragment>
      <PropertyWrapper label={t('globals.dynamic-content')}>
        <div class="mb-3 p-4 col-sm-12">
          <div
            class="alert alert-dismissible fade show alert-light m-3 text-center"
            role="alert">
            <strong>
                Modifier le contenu
            </strong>
            <br/>
            <br/>
            <a className='btn btn-primary' target='_blank' href={`${process.env.REACT_APP_FRONTEND_URL}/template/${component.contentId}/content/${component.html}`}>
                {t('globals.modify')}
            </a>
          </div>
        </div>
      </PropertyWrapper>

      <PropertyWrapper label={t('globals.hide-property')}>
        <div class="mb-3 p-4 col-sm-12">
          <label class=" form-label" for="input-model">
            {t('globals.hide-device-label')}{' '}
          </label>
          <br />
          <Hide component={component} />
        </div>
      </PropertyWrapper>
    </React.Fragment>
  );
}

export default DynamicContent;
