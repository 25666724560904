import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import "./FormRange.scss"


function FormRange({ t, val, component, name, min = 0, max = 1, step = 0.1, onChange }): React.Node {
  const [value, setValue] = useState(null);
  const [unit, setUnit] = useState(null);
  const { page, updatePage } = useEditor();

  useEffect(() => {
    if (val && val.endsWith && val.endsWith("%")) {
      setValue(val.slice(0, val.length - 1));
      setUnit("%");
    } else {
      setValue(val);
    }
  }, [val]);

  const handelOnChange = (ev) => {
    setValue(ev.target.value);
    if (onChange) {
      const _val = (unit) ? `${ev.target.value}${unit}` : ev.target.value;
      onChange(component, _val);
    }
    updatePage(page, true);
  };

  return (
    <React.Fragment>
      <input
        name={name}
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        className="form-range"
        onChange={handelOnChange} />

    </React.Fragment>
  );
}

export default FormRange;
