import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./index.scss"

function EmptyCol({ children }) {
  const { t } = useTranslation('editor');

  return (
    <React.Fragment>
      <div className="empty_col">
        {t('canvas.add-element-here')}
      </div>
    </React.Fragment>
  );
}

export default EmptyCol;
