import './App.css';
import { EditorProvider } from '@contexts/EditorContext';
import PagesRouter from './views/PagesRouter';
import { ApplicationProvider } from "@contexts/ApplicationContext";
import './i18n';

function App() {
  return (
    <ApplicationProvider>
      <EditorProvider>
        <div className="App">
          <PagesRouter />
        </div>
      </EditorProvider>
    </ApplicationProvider>
  );
}

export default App;
