import React, { useRef } from 'react';
import clsx from 'clsx';
import "./index.scss"
import _ from 'lodash';

let defaultImage = `/libs/builder/icons/image.svg`;

function Image({ content }) {

  const ref = useRef()

  return (
    <React.Fragment>
      <div 
        key={content.uuid}
        ref={ref}
        className={clsx('image_container', content.class)}
        style={{ ..._.pick(content.style, ["maxWidth", "textAlign", "paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]) }}
      >
        <img
          width={content.attributes?.width}
          className={clsx('image_content', 'unselectable')}
          selectable="true"
          src={(content.src) ? content.src : defaultImage}
        />
      </div>
    </React.Fragment>
  );
}

export default Image;
