import React, { useEffect, useState } from 'react';
import { useEditor } from '../../../../contexts/EditorContext';
import { useApplication } from '../../../../contexts/ApplicationContext';
import css from './TopPanel.scss';
import { compile, headlessCompile } from "@services/domain/RenderService";
import { saveContentTemplate } from "@services/domain/TemplatesService";
import Preview from './preview/Preview';
import { useTranslation } from 'react-i18next';

function TopPanel() {
  const { isDynamicContent, metaData } = useApplication();
  const { page, updatePage, pageHistory, undo, redo } = useEditor();
  const { t } = useTranslation('editor');

  const [previewDevice, setPreviewDevice] = useState(0); // 0: Non, 1: Mobile, 2: Desktop

  const [isSaving, setIsSaving] = useState(false);

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    setCanUndo(pageHistory.canUndo);
    setCanRedo(pageHistory.canRedo);
  }, [pageHistory]);


  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);


  const receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case 'AppOnSaved':
        setIsSaving(false);
        break;
    }
  }

  const handelSave = async (ev) => {
    // window.parent.postMessage({ message: "EditorSaveSuccess" }, process.env.REACT_APP_FRONTEND_URL);
      // return
    setIsSaving(true)
    let data = isDynamicContent ? await headlessCompile(page) : await compile(page);

    let contentTemplateBody = {
      json_content: JSON.stringify(page),
      html_content: data
    }

    saveContentTemplate(metaData.templateId, metaData.contentId, contentTemplateBody)
      .then((data) => {
        window.parent.postMessage({ message: "EditorSaveSuccess" }, process.env.REACT_APP_FRONTEND_URL);
      })
      .catch((err) => {
        window.parent.postMessage({ message: "EditorSaveError" }, process.env.REACT_APP_FRONTEND_URL);
      })
      .finally(() => {
        setIsSaving(false)
      });
  };

  const handelUndo = (ev) => {
    undo();
  }
  const handelRedo = (ev) => {
    redo();
  }

  const handelPreview = async (ev, device) => {
    setPreviewDevice(device)
  }

  return (
    <React.Fragment>

      {previewDevice > 0 && (
        <Preview
          device={previewDevice}
          onClose={() => {
            setPreviewDevice(0);
          }}
        />
      )}

      <div id="top-panel">
        <div className="btn-group float-start" role="group">
          {(metaData) && (
            <div className="metadata">
              <a target="_parent" href={(metaData.backLink ? metaData.backLink : "#")}>
                <i className="la la-angle-left" />
              </a>
              <span className="content">
                <strong>{t('top-panel.name')} :</strong> {metaData.name}&nbsp;&nbsp; <strong>{t('top-panel.language')} :</strong> {metaData.language.label}
              </span>
            </div>
          )}
        </div>
        <div className="btn-group me-3" role="group">
          <button
            className="btn btn-light"
            title="Undo"
            id="undo-btn"
            disabled={!canUndo}
            onClick={handelUndo}
            data-vvveb-action="undo"
            data-vvveb-shortcut="ctrl+z">
            <i className="la la-undo" />
          </button>

          <button
            className="btn btn-light la-flip-horizontal"
            title="Redo"
            disabled={!canRedo}
            onClick={handelRedo}
            id="redo-btn"
            data-vvveb-action="redo"
            data-vvveb-shortcut="ctrl+shift+z">
            <i className="la la-undo" />
          </button>
        </div>

        <div className="btn-group me-3 float-end" role="group">
          <button
            className="btn btn-primary btn-icon"
            title="Export (Ctrl + E)"
            id="save-btn"
            onClick={handelSave}>
            <i className="la la-save" />
            <span data-v-gettext>{t('top-panel.save')}</span>
            {(isSaving) && (
              <i className="la la-spinner la-spin" />
            )}
          </button>
        </div>

        <div className="btn-group float-end me-3 responsive-btns" role="group">
          <button
            id="mobile-view"
            data-view="mobile"
            className="btn btn-light"
            title="Mobile view"
            data-vvveb-action="viewport"
            onClick={(ev) => handelPreview(ev, 1)}>
            <i className="la la-mobile" />
          </button>

          <button
            id="desktop-view"
            data-view=""
            className="btn btn-light"
            title="Desktop view"
            data-vvveb-action="viewport"
            onClick={(ev) => handelPreview(ev, 2)}>
            <i className="la la-laptop" />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TopPanel;
