import React from 'react';
import { useEditor } from '@contexts/EditorContext';
import PropertyWrapper from '../style-tab/wrapper/PropertyWrapper';
import ConditionBlock from './ConditionBlock';
import Comment from './Comment';
import { useTranslation } from 'react-i18next';
import { useApplication } from '@contexts/ApplicationContext';
import { tag, template } from '@mkz/emailing-utilities';

const AdvancedTab = ({ metadata }) => {
  const { page, selectedElement } = useEditor();
  const { dynamicContents } = useApplication();
  const { t } = useTranslation('editor');

  return (
    <div className="tab-content">
      <div
        className="tab-pane fade show active contents"
        id="advanced-tab"
        data-section="advanced"
        role="tabpanel"
        aria-labelledby="content-tab">
        {!selectedElement && (
          <div
            class="alert alert-dismissible fade show alert-light m-3"
            role="alert">
            <strong>{t('right-panel.advanced.no-item-selected-title')}</strong>
            <br /> {t('right-panel.advanced.no-item-selected-message')}
          </div>
        )}

        {selectedElement && (
          <PropertyWrapper label={t('right-panel.advanced.comment-property')}>
            <div class="mb-3 p-2  col-sm-12">
              <Comment />
            </div>
          </PropertyWrapper>
        )}

        {selectedElement && selectedElement.type != 'body' && (
          <PropertyWrapper
            label={t('right-panel.advanced.block-condition-property')}>
            <div class="mb-3 p-2  col-sm-12">
              <ConditionBlock />
            </div>
          </PropertyWrapper>
        )}

        <PropertyWrapper label={t('right-panel.advanced.merge-tags-property')}>
          <div class="mb-3 p-2  col-sm-12">
            {page &&
              tag
                .extractMergeTags(
                  template.applyDynamicContentToJson(
                    page,
                    dynamicContents,
                    metadata.language.label
                  )
                )
                .map((mergeTag) => {
                  return (
                    <React.Fragment>
                      <span className="badge btn-primary">{mergeTag}</span>
                      &nbsp;
                    </React.Fragment>
                  );
                })}
          </div>
        </PropertyWrapper>
      </div>
    </div>
  );
};

export default AdvancedTab;
