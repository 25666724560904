import axios from 'axios';
import urlcat from 'urlcat';

export const urlBuilder = (url: string, params: object = {}) => {
  return urlcat(url, params);
};

const axiosClient = axios.create({
  timeout: 60000,
  headers: {},
  withCredentials: true,
});

axiosClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers = {
    ...config.headers,
    authorization: 'Bearer ' + localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG)
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export {axiosClient};
