import { useApplication } from '@contexts/ApplicationContext';
import { useEditor } from '@contexts/EditorContext';
import React, { useEffect, useState } from 'react';
const image = '/libs/builder/icons/puzzle.svg';
import { v4 as uuidv4 } from 'uuid';

export default ({ id, name = 'DynamicContent', template_content }) => {
  const { metaData } = useApplication();

  return {
    type: 'dynamic-content',
    group: 'content',
    name,
    image,
    controlContent: () => {
      return (
        <React.Fragment>
          <div className="component_control">
            <img src={image} />
            <div>{name}</div>
          </div>
        </React.Fragment>
      );
    },
    defaultContent: {
      uuid: uuidv4(),
      contentId: id,
      html: template_content.find((tc) => tc.language === metaData?.language?.value)?.id,
      group: 'content',
      type: 'dynamic-content',
      attributes: {
        width: '100'
      },
      style: {
        maxWidth: '100%' // DO NOT CHANGE
      }
    }
  };
};
