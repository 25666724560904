import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const image = '/libs/builder/icons/button.svg';
const name = 'Button';
export default () => {
    return {
        type: 'button',
        name,
        image,
        group: 'content',
        controlContent: () => {
            return (
                <React.Fragment>
                    <div className='component_control' >
                        <img src={image} />
                        <div>
                            {name}
                        </div>
                    </div>
                </React.Fragment >
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": 'content',
            "type": "button",
            "style": {
                "width": "100px",
                "height": "30px",
                "textAlign": "center",
                "paddingTop": "10px",
                "paddingBottom": "10px",
                "paddingLeft": "0px",
                "paddingRight": "0px",
                "borderRadius": "5px",
                "backgroundColor": "#ffc107",
                "color": "#fff",
                "fontSize": "12px",
                "borderWidth": "1px",
                "borderStyle": "solid",
                "borderColor": "#000"
            },
            "link": "https://emailing.unty.fr",
            "text": "My Button"
        }
    }
};