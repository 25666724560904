import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import ComponentsTab from './component-tab';
import StyleTab from './style-tab';
import { useEditor } from '@contexts/EditorContext';
import AdvancedTab from './advanced-tab';
import { useApplication } from '@contexts/ApplicationContext';


function RightPanel() {

  const { metaData } = useApplication();
  const { editorAction, updateEditorAction } = useEditor();

  const tabs = [
    {
      id: 'components',
      label: 'Components',
      icon: 'la la-box',
      content: <ComponentsTab />
    },
    {
      id: 'styles',
      label: 'Style',
      icon: 'la la-box',
      content: <StyleTab />
    },
    {
      id: 'advanced',
      label: 'Advanced',
      icon: 'la la-box',
      content: <AdvancedTab metadata={metaData} />
    }
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    if (editorAction?.tab) {
      setSelectedTab(tabs.find(element => editorAction.tab === element.id));
    }
  }, [editorAction]);


  const selectTab = (tab) => {
    // setSelectedTab(tab); 
    updateEditorAction({ tab: tab.id });
  }

  return (
    <div id="right-panel">
      <div className="drag-elements">
        <div className="header">
          <div className="component-properties">
            <ul
              className="nav nav-tabs nav-fill"
              id="properties-tabs"
              role="tablist">
              {tabs.map((tab, index) => (
                <li key={`nab-item-${index}`} className="nav-item components-tab">
                  <a
                    className={clsx(
                      'nav-link',
                      selectedTab.id === tab.id && 'active'
                    )}
                    data-bs-toggle="tab"
                    // href={`#${tab.id}`}
                    role="tab"
                    aria-controls={tab.id}
                    aria-selected={selectedTab.id === tab.id}
                    onClick={() => selectTab(tab)}>
                    <i className={tab.icon}></i>{' '}
                    <div>
                      <span>{tab.label}</span>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            {selectedTab.content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightPanel;
