import { axiosClient, urlBuilder } from "@services/http/Http";
import ApiBackend from "@services/http/ApiBackend";

const saveContentTemplate = (templateId, contentId, contentTemplateBody) =>
  axiosClient.put(
    urlBuilder(ApiBackend.SAVE_CONTENT_TEMPLATE, { templateId, contentId }),
    contentTemplateBody);

export {
  saveContentTemplate
};
