import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import { useTranslation } from 'react-i18next';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';

function customHtml({ component }): React.Node {
  const { page, selectedElement, updatePage } = useEditor();
  const { t } = useTranslation('editor');

  const [privateValue, setPrivateValue] = useState(null);

  useEffect(() => {
    init();
  }, [selectedElement]);

  // WE IS ITS UNECESSARY : WE HAVE TO CHECK IF
  // useEffect(() => {
  //     init()
  // }, []);

  const init = () => {
    setPrivateValue(selectedElement?.html ? selectedElement.html : '');
  };

  const onChangeHtml = (editor, data, value) => {
    selectedElement.html = value;
    updatePage(page, true);
  };

  return (
    <React.Fragment>
      <div class="customhtml__editor_section">
        <PropertyWrapper label={'Html'}>
          <div class="mb-3 p-2 col-sm-12">
            <CodeMirror
              value={privateValue}
              options={{
                mode: 'xml',
                lineNumbers: true
              }}
              onChange={onChangeHtml}
            />
          </div>
        </PropertyWrapper>
      </div>

      <PropertyWrapper label={t('globals.hide-property')}>
        <div class="mb-3 p-4 col-sm-12">
          <label class=" form-label" for="input-model">
            {t('globals.hide-device-label')}
          </label>
          <br />
          <Hide component={component} />
        </div>
      </PropertyWrapper>
    </React.Fragment>
  );
}

export default customHtml;
