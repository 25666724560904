import {axiosClient, urlBuilder} from "@services/http/Http";
import ApiBackend from "@services/http/ApiBackend";

const uploadImage = (applicationId, bodyFormData) =>
  axiosClient.post(
    urlBuilder(ApiBackend.UPLOAD_IMAGE, { applicationId }),
    bodyFormData,
    {
      headers:
        {
          "Content-Type": "multipart/form-data"
        }
    });


const getImages = (applicationId) =>
  axiosClient.get(
    urlBuilder(ApiBackend.FILE_LIST, { applicationId })
  );

const deleteImage = (applicationId, imageId) =>
  axiosClient.delete(
    urlBuilder(ApiBackend.DELETE_IMAGE, {applicationId, imageId}),
  );

export {
  uploadImage,
  getImages,
  deleteImage
};
