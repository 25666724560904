import React from 'react';
import './index.scss';
import Modal from "./index";
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ visible, hide, title, message, onConfirm, onCancel }) => {

  const { t } = useTranslation('editor');

  return (
    <Modal
      visible={visible}
      onRequestClose={hide}
      shouldCloseOnOverlayClick={false}
      title={title}
      style={{ maxWidth: '500px' }}>
      <div className="mt-4">
        <strong>{message}</strong>
      </div>
      <div className="mt-4 text-right">
        <button
          className="btn"
          onClick={e => {
            e.preventDefault();
            if (typeof onCancel === "function")
              onCancel()
            hide();
          }}
        >
          {t("globals.cancel")}
        </button>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (typeof onConfirm === "function")
              onConfirm()
            hide();
          }}
        >
          {t("globals.validate")}
        </button>
      </div>
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  visible: false,
  hide: () => undefined,
  onConfirm: () => undefined,
  onCancel: () => undefined,
  title: undefined,
  message: undefined
}

export default ConfirmationModal;
