// contexts/EditorContext.jsx
import React, { createContext, useState, useContext } from 'react';

const EditorContext = createContext({});

let historyTimeoutId;

export const EditorProvider = ({ children }) => {
  const [page, setPage] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [editorAction, setEditorAction] = useState(null); // 0: no action, 1: component 1: style, 2: advanced
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [dragElement, setDragElement] = useState(null);
  const [isDroppedElement, setIsDroppedElement] = useState(false);
  const [pageHistory, setPageHistory] = useState({
    canUndo: false,
    canRedo: false,
    index: -1,
    history: []
  });

  const archivePage = (newPage, pageHistory) => {
    clearTimeout(historyTimeoutId);
    historyTimeoutId = setTimeout(() => {
      pageHistory.history.push(JSON.parse(JSON.stringify(newPage)));
      let index = pageHistory.history.length - 1;
      setPageHistory({
        ...pageHistory,
        canRedo: false,
        canUndo: pageHistory.history.length > 1,
        index: index
      });
    }, 500);
  };

  const updatePage = (newPage, archive = false) => {
    if (archive) {
      archivePage(newPage, pageHistory);
    }

    setPage({ ...newPage });
  };

  const updateSelectedElement = (newSelectedElement) => {
    setSelectedElement(newSelectedElement);
  };

  const updateEditorAction = (newAction) => {
    setEditorAction(newAction);
  };

  const updateDragElement = (value) => {
    setDragElement(value);
  };

  const updateSelectedTarget = (value) => {
    setSelectedTarget(value);
  };
  const updateIsDroppedElement = (val) => {
    setIsDroppedElement(val);
  };

  const undo = () => {
    pageHistory.index--;
    pageHistory.canRedo = true;
    pageHistory.canUndo =
      pageHistory.history.length > 0 && pageHistory.index > 0;
    updatePage(pageHistory.history[pageHistory.index]);
    setPageHistory({ ...pageHistory });
  };

  const redo = () => {
    pageHistory.index++;
    pageHistory.canRedo = pageHistory.index < pageHistory.history.length - 1;
    pageHistory.canUndo = true;
    updatePage(pageHistory.history[pageHistory.index]);
    setPageHistory({ ...pageHistory });
  };

  return (
    <EditorContext.Provider
      value={{
        page,
        updatePage,
        selectedElement,
        updateSelectedElement,
        editorAction,
        updateEditorAction,
        dragElement,
        setDragElement,
        updateDragElement,
        selectedTarget,
        updateSelectedTarget,
        isDroppedElement,
        updateIsDroppedElement,
        pageHistory,
        undo,
        redo
      }}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = () => useContext(EditorContext);
