import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import _ from 'lodash';
import SocialMediaItemStyle from './SocialMediaItemStyle';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

function SocialMediaStyle({ component, socialMedia }): React.Node {

    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');


    const grid = 8;
    const getListStyle = (isDraggingOver) => ({
        // background: isDraggingOver ? "lightgrey" : "lightgrey",
        background: "lightgrey",
        padding: grid,
        // width: 250
    });
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "grey",
        background: "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        selectedElement.socialMedia = reorder(
            selectedElement.socialMedia,
            result.source.index,
            result.destination.index
        );

        updatePage(page, true);
    }

    const onDelete = (sm, index) => {
        // dropped outside the list

        selectedElement.socialMedia.splice(index, 1);

        updatePage(page, true);
    }

    const onAdd = () => {
        selectedElement.socialMedia.push({
            "uuid": uuidv4(),
            "link": null,
            "imgSrc": null
        });

        updatePage(page, true);
    }

    return (
        <React.Fragment>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {selectedElement.socialMedia?.map((sm, index) => (
                                <Draggable key={sm.uuid} draggableId={sm.uuid} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <SocialMediaItemStyle component={component} socialMedia={sm} />
                                            <button className='btn' onClick={() => onDelete(sm, index)}>
                                                {t("globals.delete")}
                                            </button>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <button className="btn btn-primary" onClick={onAdd}>
                {t("globals.add")}
            </button >
        </React.Fragment >
    );
}

export default SocialMediaStyle;
