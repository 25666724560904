import React, { useEffect, useState, componentDidMount } from 'react';
import { useEditor } from '@contexts/EditorContext';
import './Size.scss';

function Taille({ component, label, property, value, min = 0, onChangeVal }): React.Node {
  const [val, setVal] = useState(null);
  const [unit, setUnit] = useState(null);
  const { page, updatePage } = useEditor();

  const default_unit = "px";

  useEffect(() => {
    if (value) {
      if (value.endsWith("%")) {
        setUnit("%");
        setVal(value.slice(0, value.length - 1));
        return;
      }

      if (value.endsWith("px")) {
        setUnit("px");
        setVal(value.slice(0, value.length - 2));
        return;
      }

      setVal("100");
      setUnit("%");
    } else {
      setVal("");
      setUnit(default_unit);
    }
  }, [value]);

  const handelOnChangeVal = (ev) => {
    setVal(ev.target.value);
    let newStyle = {
      ...component.style
    };
    newStyle[property] = `${ev.target.value}${unit}`;
    component.style = newStyle;
    updatePage(page, true);
    if (onChangeVal) {
      onChangeVal(ev.target.value)
    }
  };

  const handelOnChangeUnit = (ev) => {
    setUnit(ev.target.value);
    let newStyle = {
      ...component.style
    };
    newStyle[property] = `${val}${ev.target.value}`;
    component.style = newStyle;
    updatePage(page, true);
    if (onChangeVal) {
      onChangeVal(val)
    }
  };

  return (
    <React.Fragment>
      {(label) && (
        <label class=" form-label" for="input-model">{label}</label>
      )}
      <div class=" input"><div class="input-group" id="cssunit-width">
        <input name="number" type="number" class="form-control" min={min} value={val} onChange={handelOnChangeVal} />
        <div class="input-group-append">
          <div class="size_unity">
           {unit}
          </div>
            {/* <select class="form-select small-arrow" name="unit" value={unit} onChange={handelOnChangeUnit}> */}
            {/* <option value="px">px</option> */}
            {/* <option value="%">%</option> */}
            {/* </select> */}
        </div>
      </div>
      </div>

    </React.Fragment>
  );
}

export default Taille;
