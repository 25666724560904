import { axiosClient, urlBuilder } from '@services/http/Http';
import ApiRenderer from '@services/http/ApiRenderer';

const headers = {
  Authorization: 'Basic YWRtaW46MTIzbW9rb296',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};

const preview = async (data) => {
  return new Promise(async (resolve, reject) => {
    axiosClient
      .post(ApiRenderer.PREVIEW, data, {
        headers
      })
      .then((response) => {
        resolve(response.data?.html);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const compile = async (data) => {
  return new Promise(async (resolve, reject) => {
    axiosClient
      .post(ApiRenderer.COMPILE, data, {
        headers
      })
      .then((response) => {
        resolve(response.data?.html);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const headlessCompile = async (data) => {
  return new Promise(async (resolve, reject) => {
    axiosClient
      .post(ApiRenderer.HEADLESS_COMPILE, data, {
        headers
      })
      .then((response) => {
        resolve(response.data?.html);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { compile, headlessCompile, preview };
