import React from 'react';
import ControlGroups from './ControlGroups';

import { Component as Button } from '@views/editor/models/button';
import { Component as Image } from '@views/editor/models/image';
import { Component as Hr } from '@views/editor/models/hr';
import { Component as Text } from '@views/editor/models/text';
import { Component as CustomHtml } from '@views/editor/models/custom-html';
import { Component as SocialMedia } from '@views/editor/models/social-media';
import { Components as BlockComponents } from '@views/editor/models/row';
import './index.scss';
import { useApplication } from '@contexts/ApplicationContext';
import DynamicContent from '@views/editor/models/dynamic-content/Component';

const ContentComponents = [Button, Image, Hr, Text, SocialMedia, CustomHtml];

const ComponentsTab = () => {
  const { isDynamicContent, dynamicContents } = useApplication();

  return (
    <div className="tab-content">
      <div
        className="tab-pane fade show active components"
        id="components"
        data-section="components"
        role="tabpanel"
        aria-labelledby="components-tab">
        <div className="drag-elements-sidepane sidepane">
          <ul className="clearfix">
            <ControlGroups name="Composants" components={ContentComponents} />
          </ul>
          {!isDynamicContent && dynamicContents?.length > 0 && (
            <ul className="clearfix">
              <ControlGroups
                name="Contenu dynmaiques"
                components={dynamicContents.map(DynamicContent)}
              />
            </ul>
          )}
          <ul className="clearfix">
            <ControlGroups name="Blocks" components={BlockComponents} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComponentsTab;
