import React, {useEffect, useState} from 'react';
import {useEditor} from '@contexts/EditorContext';
import {useApplication} from '@contexts/ApplicationContext';
import Modal from '@components/modal';
import './Preview.scss';
import { preview as renderPreview} from "@services/domain/RenderService";
import { useTranslation } from 'react-i18next';

function Preview({device, onClose}) {
  
  const { dynamicContents, metaData } = useApplication();

  const {page} = useEditor();
  const { t } = useTranslation('editor');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);

  function closeModal() {
    setModalIsOpen(false);
    if (onClose) {
      onClose();
    }
  }

  useEffect(async () => {
    if (device > 0) {
      setIsLoading(true);
      setModalIsOpen(true);
      const data = await renderPreview({page, dynamicContents, language: metaData.language.value });
      setContent(data);
      setIsLoading(false);
    }
  }, [device]);

  return (
    <Modal
      visible={modalIsOpen}
      hide={closeModal}
      style={{
        width: "80%",
      }}
      title={(device === 1) ? t("top-panel.preview-mobile-modal-title") : t("top-panel.preview-dekstop-modal-title")}
    >
      {(device === 1) && (
        <div className="preview preview__mobile">
          <img className="preview__mobile__icon" src="/preview/phone.png"/>
          {isLoading && (
            <i className="la la-spinner la-spin preview__loading"/>
          )}
          {(content) && (
            <div className="preview__mobile__content">
              <iframe className="preview__mobile__contentaa" name={content} src="/preview"/>
            </div>
          )}
        </div>
      )}

      {(content && device === 2) && (
        <div className="preview preview__desktop">
          <div className="preview__deskotp__content">
            <iframe width={"700px"} name={content} style={{height: "100%"}} src="/preview"/>
          </div>
        </div>
      )}
      <div className="text-right">
        <button className="btn btn-primary" onClick={closeModal}>{t("globals.close")}</button>
      </div>
    </Modal>
  );
}

export default Preview;
