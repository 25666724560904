import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import { filter, pull, without } from "lodash";
import clsx from "clsx";
import "./index.scss";

function PropertyWrapper({ label, children }): React.Node {
  const [showBlock, setShowBlock] = useState(true);
  return (
    <React.Fragment>
      <div className='propertywrapper'>
        <label className="propertywrapper__title" data-header="alignement" for={`header_${label}`} onClick={() => setShowBlock(!showBlock)}>
          <span>{label}</span>
          <div className={clsx("la", "propertywrapper__title__arrow", "header-arrow", (showBlock) ? "arrow_open" : "arrow_close")} />
        </label>
        {showBlock && 
          <div className="propertywrapper__content section row" data-section={label}>
          {children}
        </div>}
      </div>
    </React.Fragment>
  );
}

export default PropertyWrapper;
