import React, { useState, useEffect } from 'react';
import { useEditor } from '@contexts/EditorContext';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './index.scss';

const Comment = () => {
  const { selectedElement } = useEditor();
  const [val, setVal] = useState(null);
  const { t } = useTranslation('editor');

  useEffect(() => {
    setVal((selectedElement.comment?.value) ? selectedElement.comment.value : '');
  }, []);

  useEffect(() => {
    setVal((selectedElement.comment?.value) ? selectedElement.comment.value : '');
  }, [selectedElement]);

  const handelOnChange = (ev) => {
    setVal(ev.target.value);
    selectedElement.comment = {
      ...selectedElement.comment,
      value: ev.target.value
    }
  }

  return (
    <React.Fragment>
      <textarea
        name="number"
        className={clsx("form-control")}
        value={val}
        onChange={handelOnChange}>
      </textarea>
    </React.Fragment>
  );
};

export default Comment;
