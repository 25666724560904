import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    lng: 'fr',
    ns: ['common', 'editor'], // first loaded ns files by default
    defaultNS: 'common',
    fallbackNS: 'common',
    keySeparator: ".",  // to support nested translations
    fallbackLng: false,
    react: {
      useSuspense: false,
    }
  });

export default i18n;
