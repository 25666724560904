import React, { useRef } from 'react';
import clsx from 'clsx';
import { pick } from 'lodash';
import "./index.scss"
import ParagraphTextEditor from '@views/editor/components/right-panel/style-tab/properties/text-editor/ParagraphTextEditor';
import { useEditor } from '@contexts/EditorContext';

function Text({ colomn, content }) {
  const { updatePage, page } = useEditor();

  const ref = useRef(null);

  const text_toolbar_id = `text-toolbar-${content.uuid}`;

  const toolbar_size_x = 450; // in px. <==== BE SURE TO CHANGE THIS IF CHANGE EDITOR TOOLBAR
  const toolbar_size_y = 120; // in px. <==== BE SURE TO CHANGE THIS IF CHANGE EDITOR TOOLBAR

  const toolbar_top_spacing_padding = 50; // in px

  return (
    <React.Fragment>
      <div
        key={content.uuid}
        ref={ref}
        className={clsx('text_container', content.class)}
        style={{ ...pick(content.style, ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]) }}
      >

        <div
          id={text_toolbar_id}
          className={clsx('text_container__toolbar',
            (ref?.current?.getBoundingClientRect().y - toolbar_top_spacing_padding - toolbar_size_y < 0) && "text_container__toolbar__bottom",
            (ref?.current?.getBoundingClientRect().x + toolbar_size_x - document?.getElementsByClassName("body_container")[0]?.clientWidth > 0) && "text_container__toolbar__right",
            content.class)}
        />

        <div
          className={clsx('text_container__text_content text_link_'+content.uuid, `text_color_${content.uuid}`, content.class)} >
          <style jsx>{content.attributes?.linkColor ? `
              .text_link_${content.uuid} a {
               color: ${content.attributes?.linkColor};
              }
            ` : ''}</style>
            
            <style jsx>{content.style?.color ? `
              .text_color_${content.uuid} p {
               color: ${content.style?.color} !important;
              }
            ` : ''}</style>
          <ParagraphTextEditor
            fixedElement={`#${text_toolbar_id}`}
            onChange={(value) => {
              content.content = value
              updatePage(page, true)
            }}
            value={content.content}
          />
        </div>
      </div>
    </React.Fragment >
  );
}

export default Text;
