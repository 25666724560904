const API_URL = process.env.REACT_APP_BACKEND_URL;

const ApiBackend = {
  UPLOAD_IMAGE: `${API_URL}/pro/application/:applicationId/file-manger`,
  FILE_LIST: `${API_URL}/pro/application/:applicationId/file-manger`,
  DELETE_IMAGE: `${API_URL}/pro/application/:applicationId/file-manger/:imageId`,
  SAVE_CONTENT_TEMPLATE: `${API_URL}/pro/template/:templateId/content/:contentId`,
};

export default ApiBackend;
