import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import ColorPicker from '@views/editor/components/right-panel/style-tab/properties/color-picker/ColorPicker';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import FormRange from '@views/editor/components/right-panel/style-tab/properties/form-range/FormRange';
import { useTranslation } from 'react-i18next';


function Hr({ component }): React.Node {
    
    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [textAlign, setTextAlign] = useState(null);

    const [width, setWidth] = useState(null);

    const [paddingTop, setPaddingTop] = useState(null);
    const [paddingBottom, setPaddingBottom] = useState(null);
    const [paddingLeft, setPaddingLeft] = useState(null);
    const [paddingRight, setPaddingRight] = useState(null);

    const [borderTopWidth, setBorderTopWidth] = useState(null);
    const [borderTopStyle, setBorderTopStyle] = useState(null);
    const [borderTopColor, setBorderTopColor] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        console.log("selectedElement.style", selectedElement.style);
        setTextAlign(selectedElement.style.textAlign);

        setWidth(selectedElement.style.width);

        setPaddingTop(selectedElement.style.paddingTop);
        setPaddingBottom(selectedElement.style.paddingBottom);
        setPaddingLeft(selectedElement.style.paddingLeft);
        setPaddingRight(selectedElement.style.paddingRight);

        setBorderTopWidth(selectedElement.style.borderTopWidth);
        setBorderTopStyle(selectedElement.style.borderTopStyle);
        setBorderTopColor(selectedElement.style.borderTopColor);

    };

    const onChangeTextAlign = (component, value) => {
        setTextAlign(value);
        if (value != 'none') {
            component.style = { ...component.style, textAlign: value };
        } else {
            component.style = { ...component.style, textAlign: '' };
        }
    };

    const onChangeWidth = (component, value) => {
        setWidth(value);
        if (value != 'none') {
            component.style = { ...component.style, width: value };
        } else {
            component.style = { ...component.style, width: '' };
        }
    };

    const onChangeBandelBorderColor = (comp, value) => {
        let _val = (value) ? value : undefined;
        setBorderTopColor(value);
        selectedElement.style = {
            ...selectedElement.style,
            borderTopColor: _val
        }
        updatePage(page, true)
    }

    return (
        <React.Fragment>

            <PropertyWrapper
                label={t('globals.size-property')}>
                <div key={`hr-width-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">Taille : {width}</label>
                    {/* CAN TAKE "PX" unit ALSO */}
                    <FormRange
                        val={width}
                        component={component}
                        max={100}
                        onChange={onChangeWidth}
                    />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.alignement-property')}>
                <div key={`hr-text-align-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12" data-key="text-align">
                    <div class=" input">
                        <div class="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">

                            <TextAlign
                                val={textAlign}
                                component={component}
                                onChange={onChangeTextAlign}
                                hiddenOptions={["", "justify"]}
                            />

                        </div>
                    </div>
                </div>

            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.spacing-property')}>

                <div key={`hr-spacing-top-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Top"} property={"paddingTop"} value={paddingTop} component={component} />
                </div>
                <div key={`hr-spacing-bottom-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom"} property={"paddingBottom"} value={paddingBottom} component={component} />
                </div>
                <div key={`hr-spacing-left-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Left"} property={"paddingLeft"} value={paddingLeft} component={component} />
                </div>
                <div key={`hr-spacing-right-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Right"} property={"paddingRight"} value={paddingRight} component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.style-property')}>
                <div key={`hr-border-top-width-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Width"} property={"borderTopWidth"} value={borderTopWidth} component={component} />
                </div>
                <div key={`hr-border-color-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <label class="form-label" for="input-model">{t('globals.color-label')}</label>
                    <ColorPicker
                        color={borderTopColor}
                        component={component}
                        onChange={onChangeBandelBorderColor}
                    />
                </div>

                <div key={`hr-border-top-style-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <label class="form-label" for="input-model">{t('globals.style-label')}</label>
                    <select class="form-select small-arrow" name="borderStyle" value={borderTopStyle}
                        onChange={(ev) => {
                            setBorderTopStyle(ev.target.value);
                            component.style = {
                                ...component.style,
                                borderTopStyle: ev.target.value
                            };
                            updatePage(page, true);
                        }}>
                        <option value="none">none</option>
                        <option value="solid">solid</option>
                        <option value="dashed">dashed</option>
                    </select>
                </div>

            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('globals.hide-device-label')}</label><br />
                    <Hide
                        component={component}
                    />
                </div>
            </PropertyWrapper>
        </React.Fragment >
    );
}

export default Hr;
