import React, { useEffect } from 'react';
import { Style as Image } from '@views/editor/models/image';
import { Style as Button } from '@views/editor/models/button';
import { useEditor } from '@contexts/EditorContext';
import { Style as Hr } from '@views/editor/models/hr';
import { Style as Body } from '@views/editor/models/body';
import { Style as CustomHtml } from '@views/editor/models/custom-html';
import { Style as SocialMedia } from '@views/editor/models/social-media';
import { Style as DynamicContent } from '@views/editor/models/dynamic-content';
import Text from '@views/editor/models/text/Style';
import Row from '@views/editor/models/row/Style';

const ContentsTab = () => {
  const { selectedElement } = useEditor();

  return (
    <div className="tab-content">
      <div
        className="tab-pane fade show active contents"
        id="content-tab"
        data-section="content"
        role="tabpanel"
        aria-labelledby="content-tab">
        {!selectedElement && (
          <div
            class="alert alert-dismissible fade show alert-light m-3"
            role="alert">
            <strong>No selected element!</strong>
            <br /> Click on an element to edit.
          </div>
        )}

        {selectedElement?.type === 'button' && (
          <Button component={selectedElement} />
        )}

        {selectedElement?.type === 'image' && (
          <Image component={selectedElement} />
        )}

        {selectedElement?.type === 'hr' && (
          <Hr component={selectedElement} />
        )}

        {selectedElement?.type === 'text' && (
          <Text component={selectedElement} />
        )}

        {selectedElement?.type === 'social-media' && (
          <SocialMedia component={selectedElement} />
        )}

        {selectedElement?.type === 'body' && (
          <Body component={selectedElement} />
        )}

        {selectedElement?.type === 'row' && (
          <Row component={selectedElement} />
        )}

        {selectedElement?.type === 'custom-html' && (
          <CustomHtml component={selectedElement} />
        )}

        {selectedElement?.type === 'dynamic-content' && (
          <DynamicContent component={selectedElement} />
        )}
      </div>
    </div>
  );
};

export default ContentsTab;
