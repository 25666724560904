import React, { useRef, useEffect, useState } from 'react';
import { Canvas as Body } from '@views/editor/models/body';
import { Canvas as Row } from '@views/editor/models/row';
import { useEditor } from '@contexts/EditorContext';
import ReactRuler from 'mb-sketch-ruler';

import './Canvas.scss';

const thick = 16;

function Canvas({}) {
  const { page } = useEditor();
  const [lines, setLines] = useState({
    h: [],
    v: []
  });
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scale, setScale] = useState(2);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const canvasRef = useRef(null);

  const handleScroll = () => {
    const screensRect = document
      .querySelector('#canvas')
      .getBoundingClientRect();
    const canvasRect = document
      .querySelector('#canvas')
      .getBoundingClientRect();

    const startX = (screensRect.left + thick - canvasRect.left) / scale;
    setStartX(startX);
    const startY = (screensRect.top + thick - canvasRect.top) / scale;
    console.log('startX', startX, startY, screensRect);
    setStartY(startY);
  };

  const handleWheel = (e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      const nextScale = parseFloat(
        Math.max(0.2, scale - e.deltaY / 500).toFixed(2)
      );
      setScale(nextScale);
    }
  };
  const handleLine = (lines) => {
    setLines(lines);
  };

  const rectWidth = 160;
  const rectHeight = 200;

  const canvasStyle = {
    width: rectWidth,
    height: rectHeight,
    transform: `scale(${scale})`
  };

  const shadow = {
    x: 0,
    y: 0,
    width: rectWidth,
    height: rectHeight
  };

  useEffect(() => {
    setHeight(canvasRef.current.offsetHeight);
  }, [canvasRef?.current?.offsetHeight]);

  useEffect(() => {
    setWidth(canvasRef.current.offsetWidth);
  }, [canvasRef?.current?.offsetWidth]);

  return (
    <React.Fragment>
      <div id="canvas" ref={canvasRef}>
        <ReactRuler
          thick={thick}
          scale={1}
          width={width}
          height={height}
          startX={startX}
          startY={startY}
          shadow={shadow}
          horLineArr={lines.h}
          verLineArr={lines.v}
          handleLine={handleLine}
          cornerActive={false}
          // onCornerClick={this.handleCornerClick}

          isOpenMenuFeature={true}
          // handleShowRuler={this.handleShowRuler}
          isShowReferLine={true}
          // handleShowReferLine={this.handleShowReferLine}
        />
        <div id="body">
          <Body
            body={page.body}
            style={page.body.style}
            className={page.body.class}>
            {page.body?.rows?.map((row, index) => {
              return <Row body={page.body} row={row} index={index} />;
            })}
          </Body>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Canvas;
