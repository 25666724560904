import React, { useState, useEffect } from 'react';
import { useEditor } from '@contexts/EditorContext';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './index.scss';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import { tag } from '@mkz/emailing-utilities';

const ConditionBlock = () => {
  const { selectedElement } = useEditor();
  const [val, setVal] = useState(null);
  const [isValideCondition, setIsValideCondition] = useState(true);
  const { t } = useTranslation('editor');

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [selectedElement]);

  const init = () => {
    setVal(
      selectedElement.condition?.value ? selectedElement.condition.value : ''
    );
    if (selectedElement.condition?.value) {
      try {
        // eval(selectedElement.condition?.value)
        setIsValideCondition(true);
      } catch (e) {
        setIsValideCondition(false);
      }
    } else {
      setIsValideCondition(true);
    }
  };

  const handelOnChange = (editor, data, value) => {
    // setVal(value);
    selectedElement.condition = {
      ...selectedElement.condition,
      value
    };
    try {
      const mergeTags =  tag.extractMergeTags(value);
      let compiledValue = value;
      mergeTags.forEach(tag => compiledValue = compiledValue.replaceAll(`$\{${tag}\}`, `"${tag}"`))
      eval(compiledValue)   // TODO SET A CONTROL FOR SETTING A CONDITION
      setIsValideCondition(true);
    } catch (e) {
      console.log('e', e)
      setIsValideCondition(false);
    }
  };

  return (
    <React.Fragment>
      <div key={`conditionblock-${selectedElement.uuid}`}>
        <CodeMirror
          options={{
            mode: 'javascript',
            lineNumbers: true
          }}
          name="number"
          className={clsx(
            'form-control',
            !isValideCondition && 'conditionblock__form__error'
          )}
          value={val}
          onChange={handelOnChange} />
        {!isValideCondition && (
          <React.Fragment>
            <span className={'conditionblock__alert'}>
              <i className="la la-exclamation-circle conditionblock__alert__icon" />{' '}
              {t('right-panel.advanced.not-valide-condition-message')}
            </span>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConditionBlock;
