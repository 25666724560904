import React, { useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom'
import {Canvas as Content} from '../content';
import _, { omit, pick, has } from 'lodash'
import { useEditor } from '@contexts/EditorContext';
import DragIndicator from '@views/editor/components/canvas/drag-indicator';
import EmptyCol from './empty-col';

function Colomn({ page, colomn, contents, style, className }) {
    const columnRef = useRef();

    const [showDragMessage, setShowDragMessage] = useState(0);

    const { updatePage, dragElement, updateIsDroppedElement } = useEditor();


    const handelDrag = (ev) => {
        const element = ReactDOM.findDOMNode(columnRef.current);
        if (dragElement && dragElement.group === "content") {
            updateIsDroppedElement(false);
            if (ev.type == "dragenter") {
                if (element === ev.target || (!contents || contents.length === 0)) {
                    setShowDragMessage(1);
                    ev.preventDefault();
                }
            } else if (ev.type == "dragover") {
                console.log("dragover colomn");
                if (element === ev.target || (!contents || contents.length === 0)) {
                    setShowDragMessage(1);
                    ev.preventDefault();
                }
            } else if (ev.type == "dragleave") {
                setShowDragMessage(0);
                ev.preventDefault();
            }
        }
    };

    const handelDrop = (ev, index) => {
        if (dragElement.group === "content") {
            if (showDragMessage === 1) {
                colomn.contents.push(dragElement);
            }
            setShowDragMessage(0);
            updateIsDroppedElement(true);
            updatePage(page, true);
        }
    };

    return (
        <React.Fragment>
            <div
                ref={columnRef}
                key={colomn}
                draggable={(dragElement) ? true : false}
                className={"col " + className}
                style={style}
                onDragEnter={handelDrag}
                onDragOver={handelDrag}
                onDragLeave={handelDrag}
                onDrop={(ev) => {
                    handelDrop(ev, 0)
                }}>



                {contents?.map((content, index) => {
                    return (
                        <Content
                            page={page}
                            colomn={colomn}
                            index={index}
                            content={content}
                            style={content.style}
                            className={content.class} />
                    )
                })}

                {(showDragMessage === 1) && (
                    <DragIndicator />
                )}

                {/* ONLY IF COLUMN IS EMPTY */}
                {(!contents || contents.length === 0) && (
                    <EmptyCol />
                )}

            </div>
        </React.Fragment>
    );
}

export default Colomn;
