import React, { useRef, useState } from 'react';
import * as ReactDOM from 'react-dom'
import _, { pick } from 'lodash'
import { Canvas as Button } from '@views/editor/models/button';
import { Canvas as Image } from '@views/editor/models/image';
import { Canvas as Hr } from '@views/editor/models/hr';
import { Canvas as Text } from '@views/editor/models/text';
import { Canvas as SocialMedia } from '@views/editor/models/social-media';
import { Canvas as CustomHtml } from '@views/editor/models/custom-html';
import { Canvas as DynamicContent } from '@views/editor/models/dynamic-content';
import { v4 as uuidv4 } from 'uuid';
import { clone } from './utils';
import { useEditor } from '@contexts/EditorContext';
import clsx from 'clsx';
import "./index.scss"
import DragIndicator from '@views/editor/components/canvas/drag-indicator';

function Content({ page, colomn, index, content, style, className }) {

  const contentRef = useRef();

  const [showDragMessage, setShowDragMessage] = useState(0);

  const { updatePage, selectedElement, updateSelectedElement, dragElement, setDragElement, updateSelectedTarget, isDroppedElement, updateIsDroppedElement, updateEditorAction } = useEditor();

  const [displayHighlight, setDisplayHighlight] = useState(false);

  const handelClone = (ev) => {
    const uuid = uuidv4();
    const newContent = { ...content, uuid };
    colomn.contents.splice(index, 0, newContent);
    updatePage(page, true);
  };

  const deleteContent = (ev) => {
    if (content.uuid) {
      _.remove(colomn.contents, {
        uuid: content.uuid
      });
    } else {
      _.remove(colomn.contents);
    }
    
    updatePage(page, true);
  };

  const handelMove = (ev) => {
    if (ev.type === "dragstart") {
      setDragElement(content);
    } else if (ev.type === "dragend") {
      if (isDroppedElement) {
        setDragElement(null);
        _.remove(colomn.contents, {
          uuid: dragElement.uuid
        });
        updateIsDroppedElement(false);
        updatePage(page, true);
      }
    }
  };

  const handelSelect = (ev) => {
    updateSelectedElement(content);
    updateSelectedTarget(ev.target);
    updateEditorAction({tab: "styles"});
    ev.stopPropagation();
  }

  const handelSelectAdvance = (ev) => {
    updateSelectedElement(content);
    updateSelectedTarget(ev.target);
    updateEditorAction({tab: "advanced"});
    ev.stopPropagation();
  }

  const handelDrag = (ev) => {
    // console.log("handelDrap content");
    updateIsDroppedElement(false);
    // console.log("************* element === ev.target", element === ev.target, element, ev.target);
    if (dragElement && dragElement.group === "content") {
      if (ev.type == "dragover") {
        // console.log("dragover");
        // FIND DROG OVER TOP OF BOTTOM THE ELEMENT
        var element = ReactDOM.findDOMNode(contentRef.current);
        var rect = element.getBoundingClientRect();
        const coordinates = [ev.pageX - rect.left, ev.pageY - rect.top];
        if (rect.height / 2 > coordinates[1]) {
          setShowDragMessage(1);
        } else {
          setShowDragMessage(2);
        }
      } else if (ev.type == "dragleave") {
        setShowDragMessage(0);
      }
      ev.preventDefault();
      ev.stopPropagation();
    }
  };

  const handelDrop = (ev, index) => {
    if (dragElement.group === "content") {
      if (showDragMessage === 1) {
        // PREPEND ELEMENT
        colomn.contents.splice(index, 0, clone(dragElement));
      } else if (showDragMessage === 2) {
        // APPEND ELEMENT
        colomn.contents.splice(index + 1, 0, clone(dragElement));
      }
      setShowDragMessage(0);
      updateIsDroppedElement(true);
      updatePage(page, true);
    }
  };

  return (
    <React.Fragment>

      {(showDragMessage === 1) && (
        <DragIndicator />
      )}
      <div
        key={`content-wrapper-${content.uuid}`}
        ref={contentRef}
        className={clsx("component_container", (displayHighlight || selectedElement === content) && "hover")}
        draggable={(dragElement) ? true : false}
        onClick={handelSelect}
        onMouseEnter={() => {
          setDisplayHighlight(true);
        }}
        onMouseLeave={() => {
          setDisplayHighlight(false);
        }}
        onDragEnter={handelDrag}
        onDragOver={handelDrag}
        onDragLeave={handelDrag}
        onDrop={(ev) => {
          handelDrop(ev, index)
        }}>

        <div
          className={clsx("component_highlight")}
          style={{ display: (displayHighlight || selectedElement === content) ? "block" : "none" }}>
          <div>
            {(content.condition?.value) && (
              <a draggable className="highlight_icon" > <i class="la la-code" onClick={handelSelectAdvance} ></i> </a>
            )}
            <a draggable className="highlight_icon" onDragStart={handelMove} onDragEnd={handelMove}> <i class="la la-arrows-alt"></i> </a>
            <a className="highlight_icon" onClick={handelClone}><i class="la la-copy"></i> </a>
            <a className="highlight_icon" onClick={deleteContent}> <i class="la la-trash"></i> </a>
          </div>
        </div>

        <div
          className="component_content"
          style={pick(page.global.row.style, ["textAlign"])}
        >
          {content.type === 'button' && <Button column={colomn} content={content} />}
          {content.type === 'image' && <Image column={colomn} content={content} />}
          {content.type === 'hr' && <Hr column={colomn} content={content} />}
          {content.type === 'text' && <Text column={colomn} content={content} />}
          {content.type === 'social-media' && <SocialMedia column={colomn} content={content} />}
          {content.type === 'custom-html' && <CustomHtml column={colomn} content={content} />}
          {content.type === 'dynamic-content' && <DynamicContent column={colomn} content={content} />}
        </div>

      </div>
      {(showDragMessage === 2) && (
        <DragIndicator />
      )}
    </React.Fragment >
  );
}

export default Content;
