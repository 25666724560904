import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
import _ from 'lodash';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/emoticons';

function TinymceEditor({ t, value, onChange, fixedElement}): React.Node {

  const editorRef = useRef(null);

  const [privateValue] = useState(value);

  const _fontsizes = "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 32px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px";

  return (
    <React.Fragment>
      <Editor
        // tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"
        // apiKey="a3unjp928i2xrza9curwxesztokouwdagzukqmkb0fbajv20"
        onInit={(evt, editor) => {
          editorRef.current = editor
        
        }}
        initialValue={privateValue}
        onEditorChange={(value) => {
          onChange(value)
        }}
        init={{
          inline: true,//see comment above
          target: true,

          fixed_toolbar_container: fixedElement,

          toolbar_mode: 'floating',
          // toolbar_location: 'bottom',
          
          selector: 'textarea',
          // toolbar_persist: true,
          plugins: 'searchreplace autolink lineheight code fullscreen image link media template table charmap hr nonbreaking anchor insertdatetime advlist lists wordcount help emoticons',
          //imagetools_cors_hosts: ['picsum.photos'],
          menubar: false,//'file edit view insert format tools table help',

          emoticons_database_url: "/libs/tinymce/emojis.min.js",

          fontsize_formats: _fontsizes,
          lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 48px 52px 56px 60px 64px 72px",
          // lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 36px",
          toolbar: [
            'fontselect fontsizeselect formatselect',
            'bold italic underline strikethrough | forecolor backcolor removeformat |  charmap emoticons  | link anchor',
            `alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist lineheight`],
          // toolbar_sticky: false,
          toolbar_sticky: true,

          importcss_append: true,
          height: 600,
          image_caption: true,
          quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
          noneditable_noneditable_class: 'mceNonEditable',
          contextmenu: 'link image imagetools table',
          // skin: 'oxide',
          content_css: ''
        }}
      />
    </React.Fragment>
  );
}

export default TinymceEditor;
