import React, { useEffect, useState } from 'react';

function Preview() {

  return (
    <React.Fragment>
      <div style={{overflowX: "hidden"}}
         dangerouslySetInnerHTML={{ __html: window.name }} />
    </React.Fragment>
  );
}

export default Preview;
