/**
 * LinksProvider
 * @module Services/http/LinksProvider
 * @description Offers methods to handle routes and API endpoints
 */

const self = {};

self.ROUTES = {
  EDITOR: '/editor',
  SECURE_EDITOR: '/editor/secure',
  PREVIEW: '/preview',
};


export default self;
