import React, { useEffect, useState } from 'react';
import './index.scss';
import TopPanel from './components/top-panel/TopPanel';
// import LeftPanel from './components/left-panel/LeftPanel';
import Canvas from './components/canvas/Canvas';
import RightPanel from './components/right-panel/RightPanel';
import { useEditor } from '../../contexts/EditorContext';
import { useApplication } from '@contexts/ApplicationContext';


const DefaultContent = require('../../contexts/DefaultContent.json');

function Editor({}) {
  const { updateApplicationId, updateDynamicContents, updateIsDynamicContent, updateMetaData } =
    useApplication();

  const { page, updatePage } = useEditor();

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    window.parent.postMessage(
      { message: 'EditorReady' },
      process.env.REACT_APP_FRONTEND_URL
    );
  }, []);

  const receiveMessage = (event) => {
    const message = event.data.message;
    if (event?.data?.metadata?.application) {
      updateApplicationId(event.data.metadata.application);
      updateIsDynamicContent(event.data.metadata.isDynamicContent);
    }

    if (event?.data?.metadata?.dynamicContents)
      updateDynamicContents(event.data.metadata.dynamicContents);

    switch (message) {
      case 'AppData':
        if (event.data.value) {
          if (typeof event.data.value === 'string') {
            try {
              updatePage(JSON.parse(event.data.value), true);
            } catch (e) {
              console.error('AppData not a JSON Object');
            }
          } else {
            updatePage(event.data.value, true);
          }
        } else {
          updatePage(DefaultContent, true);
        }

        if (event.data.metadata) {
          updateMetaData(event.data.metadata)
        }
        break;
    }
  };

  return (
    <React.Fragment>
      <div id="vvveb-builder">
        <TopPanel />

        {page && <Canvas />}

        <RightPanel />
      </div>
    </React.Fragment>
  );
}

export default Editor;
