import _Canvas  from './Canvas'
import _Style  from './Style';
import _Component  from './Component';

export const Canvas = _Canvas;
export const Style = _Style;
export const Component = _Component;


export default {};
