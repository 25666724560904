import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import clsx from "clsx";
import KeycloakService from "@services/domain/KeycloakService";

const ImageStyle = (width, height) => {
  return {
    width,
    height,
    objectFit: "contain"
  }
}

export default class Image extends PureComponent {
  constructor(props) {
    super(props);
    this.hasAdminRole = KeycloakService.hasRole("EMAILING_ADMIN_APPLICATION");
    this.state = {hovered: false};
  }

  render() {
    const {src, isSelected, onImageClick, onImageDeleteClick} = this.props
    return (
      <div className={clsx("responsive", isSelected && "selected")}
           onClick={onImageClick}
      >
        <img src={src}
             className={clsx("thumbnail", isSelected && "selected")}
             style={ImageStyle(150, 150)}
        />
        {isSelected && <div className="checked la la-check"/>}

        {this.hasAdminRole && !isSelected && <div className="delete la la-trash" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onImageDeleteClick()
        }}/>}
      </div>
    )
  }
}

Image.propTypes = {
  src: PropTypes.string,
  isSelected: PropTypes.bool
}
