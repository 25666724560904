import React  from 'react';
import { useTranslation } from 'react-i18next';
import "./index.scss"

function DragIndicator({ children }) {
  const { t } = useTranslation('editor');

  return (
    <React.Fragment>
      <div className="drag_container">
        <div className="drag_container__text_container">
        <span className="drag_container__text_container__label">
          {t('canvas.add-here')}
        </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DragIndicator;
