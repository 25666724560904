import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import './index.css'
import Image from './image'
import ConfirmationModal from "@components/modal/ConfirmationModal";

class ImagePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      picked: this.props.selectedImages,
      openDeleteConfirmationModal: false,
      selectedImage: null
    }
    this.handleImageClick = this.handleImageClick.bind(this)
    this.renderImage = this.renderImage.bind(this)
    this.handleDeleteImageClick = this.handleDeleteImageClick.bind(this)
  }

  handleImageClick(image) {
    const {multiple, onPick, maxPicks, onMaxPicks} = this.props
    const pickedImage = multiple ? this.state.picked : []

    if (pickedImage.indexOf(image) > -1) {
      pickedImage.splice(pickedImage.indexOf(image), 1)
    } else {
      if (maxPicks == undefined) {
        pickedImage.push(image)
      } else {
        if (pickedImage.size < maxPicks) {
          pickedImage.push(image)
        } else {
          onMaxPicks(image)
        }
      }
    }

    if (pickedImage) {
      this.setState({picked: pickedImage})
      onPick(multiple ? pickedImage : pickedImage[0])
    }
  }

  handleDeleteImageClick(image) {
    const {onDelete} = this.props;
    if (typeof onDelete === 'function')
      onDelete(image)
  }

  renderImage(image, i) {
    return (
      <Image
        src={image.src}
        isSelected={this.state.picked.find(img => img.value === image.value)}
        onImageClick={() => this.handleImageClick(image)}
        onImageDeleteClick={() => {
          this.setState({openDeleteConfirmationModal: true, selectedImage: image});
        }}
        key={i}
      />
    )
  }

  render() {
    const {images} = this.props
    return (
      <div className="image_picker">
        {images.map(this.renderImage)}
        <div className="clear"/>
        <ConfirmationModal 
            title="Confirmez la suppression ?" 
            message="Si vous confirmez, votre image sera définitivement effacée" 
            visible={this.state.openDeleteConfirmationModal}
            hide={() => this.setState({openDeleteConfirmationModal: false})}
            onConfirm={() => this.handleDeleteImageClick(this.state.selectedImage)}/>
      </div>
    )
  }
}

ImagePicker.defaultProps = {
  selectedImages: []
}

ImagePicker.propTypes = {
  images: PropTypes.array,
  multiple: PropTypes.bool,
  onPick: PropTypes.func,
  onDelete: PropTypes.func,
  maxPicks: PropTypes.number,
  onMaxPicks: PropTypes.func,
  selectedImages: PropTypes.array,
}

export default ImagePicker
