export const dataURLtoBlob = (dataUrl, callback) => {
  const req = new XMLHttpRequest();

  req.open('GET', dataUrl);
  req.responseType = 'arraybuffer';

  req.onload = function fileLoaded() {
    const mime = this.getResponseHeader('content-type');

    callback(new Blob([this.response], { type: mime }));
  };

  req.send();
};
