import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const name = 'Image';
const image = '/libs/builder/icons/image.svg';

export default () => {
    return {
        type: 'image',
        group: 'content',
        name,
        image,
        controlContent: () => {
            return (
                <React.Fragment>
                    <div className='component_control'>
                        <img src={image} />
                        <div>
                            {name}
                        </div>
                    </div>
                </React.Fragment>
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": "content",
            "type": "image",
            "attributes": {
                "width": "100"
            },
            "style": {
                "maxWidth": "100%", // DO NOT CHANGE
                "textAlign": "center",
                "paddingTop": "10px",
                "paddingLeft": "0px",
                "paddingBottom": "10px",
                "paddingRight": "0px"
            },
            "src": null
        }
    };
};

