import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import "./index.scss"

function Hr({ content }) {

  const ref = useRef();

  return (
    <React.Fragment>
      <div 
        key={content.uuid}
        ref={ref}
        className={clsx('customhtml__container', content.class)}
        style={{ ..._.pick(content.style, ["textAlign", "paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]) }}
        dangerouslySetInnerHTML={{__html: content.html}}
      />
    </React.Fragment>
  );
}

export default Hr;
