import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Editor from '.';

const View1Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Editor />} />
    </Routes>
  );
};

export default View1Router;
