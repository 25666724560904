import React, { useEffect, useState } from 'react';
import ImagePicker from './components/image-picker'
import ImageCropper from "@views/editor/components/image-cropper";
import { getImages, uploadImage, deleteImage } from "@services/domain/ImageService";
import { dataURLtoBlob } from "@services/utils/ImageService";
import { useApplication } from "@contexts/ApplicationContext";
import Modal from "@components/modal";
import './index.css';

const ImagePickerComponent = ({ imageList, visible, hide, onPickImage, selectedImages, allowedFileTypes }) => {
  const { applicationId } = useApplication();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTmpImage, setSelectedTmpImage] = useState(null);
  const [selectedTmpImageType, setSelectedTmpImageType] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [images, setImages] = useState(imageList);

  useEffect(() => {
    getImages(applicationId).then(({ data }) => setImages([...images, ...data.data.map(d => d.file.url)]))
  }, [])

  return (
    <React.Fragment>
      <Modal visible={visible} hide={hide} onValidate={() => onPickImage(selectedImage)}
        title={"Ajouter une image"}
      >
        <div className="text-right" >
          <input
            type="file"
            id="file"
            className="hidden"
            accept={allowedFileTypes.join()}
            onChange={async (e) => {
              if (!e || !e.target || !e.target.files || !e.target.files.length) {
                return;
              }
              const imageType = e.target.files[0].type;
              if (imageType?.includes('gif')) {
                const bodyFormData = new FormData();
                bodyFormData.append('file', e.target.files[0]);
                const { data } = await uploadImage(applicationId, bodyFormData);
                setImages([...images, data.data[0].file.url])
              } else {
                setSelectedTmpImage(URL.createObjectURL(e.target.files[0]));
                setSelectedTmpImageType(e.target.files[0].type);
                setShowCropModal(true)
              }
            }}
          />
          <label className="btn btn-primary text-right" htmlFor="file">
            Ajouter une image
          </label>
        </div>
        <ImagePicker
          images={images.map((image) => ({ src: image, value: image }))}
          onPick={(image) => setSelectedImage(image.value)}
          selectedImages={selectedImages.map((image) => ({ src: image, value: image }))}
          onDelete={(image) => {
            deleteImage(applicationId, image.value.split('/')[image.value.split('/').length - 1])
            images.splice(images.findIndex(img => img === image.value), 1)
            setImages([...images])
          }}
        />
        <div className="row mt-3">
          <button
            className="btn"
            onClick={e => {
              e.preventDefault();
              hide();
            }}
          >
            Annuler
          </button>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              if (selectedImage)
                onPickImage(selectedImage)
              hide();
            }}
          >
            Valider
          </button>
        </div>
      </Modal>
      <ImageCropper
        image={selectedTmpImage}
        imageType={selectedTmpImageType}
        visible={showCropModal}
        hide={() => setShowCropModal(false)}
        setImage={(fileData) => {
          return new Promise((resolve, reject) => dataURLtoBlob(fileData, async (blob) => {
            const bodyFormData = new FormData();
            bodyFormData.append('file', blob);
            const { data } = await uploadImage(applicationId, bodyFormData);
            setImages([...images, data.data[0].file.url])
            resolve(true);
          }));
        }}
      />
    </React.Fragment>

  )
}

ImagePickerComponent.defaultProps = {
  imageList: [],
  selectedImages: [],
  allowedFileTypes: ["image/png", "image/jpeg", "image/svg+xml", "image/gif"]
}

export default ImagePickerComponent;
