import { useEditor } from '@contexts/EditorContext';
import React from 'react';
import clsx from 'clsx'
import './index.scss'

function Body({ body, content, style, className, children }) {

  const { selectedElement, updateSelectedElement, updateSelectedTarget, updateEditorAction } = useEditor();

  const select = (ev) => {
    updateSelectedElement(body)
    updateEditorAction({ tab: "styles" });
    updateSelectedTarget(ev.target)
  }

  return (
    <React.Fragment>
      <div
        className={clsx("body_container", className)}
        id='body'
        onClick={select} style={{ ...style, minWidth: "100%" }}
      >
        <div className={clsx('body_container__sibling', (selectedElement === body) && "hover")}></div>
        {children}
      </div>
    </React.Fragment>
  );
}

export default Body;
