import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const image = '/libs/builder/icons/hr.svg';
const name = 'Separateur';

export default () => {
    return {
        type: 'hr',
        group: 'content',
        name,
        image,
        controlContent: () => {
            return (
                <React.Fragment>
                    <div className='component_control'>
                        <img src={image} />
                        <div>
                            {name}
                        </div>
                    </div>
                </React.Fragment>
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": 'content',
            "type": "hr",
            "class": "",
            "style": {
                "width": "90%",
                "textAlign": "center",
                "borderTopWidth": "1px",
                "borderTopStyle": "solid",
                "borderTopColor": "#666666",
                "paddingTop": "10px",
                "paddingRight": "0px",
                "paddingBottom": "10px",
                "paddingLeft": "0px"
            }
        }
    };
};