import React, { useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import PropertyWrapper from '../style-tab/wrapper/PropertyWrapper';

function ControlGroups({ name, components }) {
  const [showBlock, setShowBlock] = useState(true);

  const { setDragElement } = useEditor();

  const handelDragStart = (content) => {
    setDragElement(content);
  };

  const handelDragEnd = () => {
    setDragElement(null);
  };

  return (
    <PropertyWrapper
      label={name}>
      {components.map((component, index) => {
        const componentInstance = typeof component === 'function' ? component() : component;
        return (
          <div key={`cont-group-${index}`} className={componentInstance.blockSize || "col-6"}>
            <li
              draggable
              onDragStart={() => {
                handelDragStart(componentInstance.defaultContent);
              }}
              onDragEnd={handelDragEnd}
              data-drag-type="component"
              data-type={componentInstance}
            >
              {(componentInstance.controlContent) && componentInstance.controlContent()}
            </li>
          </div>
        );
      })}
    </PropertyWrapper>
  );
}

export default ControlGroups;
