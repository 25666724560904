import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import "./index.scss"


function Hr({ content }) {

  const ref = useRef();

  return (
    <React.Fragment>
      <div 
        key={content.uuid}
        ref={ref} 
        className='hr_container'>
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style={{}}
          role="presentation"
          valign="top">
          <tbody>
            <tr style={{ verticalAlign: "top" }} valign="top">
              <td className="hr_cell"
                style={_.pick(content.style, ["paddingTop", "paddingBottom", "paddingRight", "paddingLeft"])}
                valign="top">
                <table
                  className="hr_cell__content"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width={(content.style.width) ? content.style.width : "100%"}
                  style={_.pick(content.style, ["borderTopWidth", "borderTopStyle", "borderTopColor"])}
                  align={(content.style.textAlign) ? content.style.textAlign : "center"}
                  role="presentation"
                  height="0"
                  valign="top">
                  <tbody>
                    <tr>
                      <td
                        height="0"
                        valign="top">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default Hr;
