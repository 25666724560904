import React, { useRef } from 'react';
import { pick, omit } from 'lodash';
import "./index.scss"
import ButtonTextEditor from '@views/editor/components/right-panel/style-tab/properties/text-editor/ButtonTextEditor';
import clsx from 'clsx';

export const defaultContent = {
  type: 'button',
  style: {},
  text: 'My Button'
};

export const onChangeTextAlign = (component, value) => {
  if (value != 'none') {
    component.style = { ...component.style, textAlign: value };
  } else {
    component.style = { ...component.style, textAlign: '' };
  }
};

function Canvas({ content }) {

  const ref = useRef(null);

  const button_toolbar_id = `button-toolbar-${content.uuid}`;

  const toolbar_size_y = 80; // in px // in px. <==== BE SURE TO CHANGE THIS IF CHANGE EDITOR TOOLBAR
  const toolbar_size_x = 350; // in px // in px. <==== BE SURE TO CHANGE THIS IF CHANGE EDITOR TOOLBAR
  const toolbar_top_spacing_padding = 50; // in px

  return (
    <React.Fragment>
      <div
        key={content.uuid}
        ref={ref}
        className="button_container"
        style={{
          ...pick(content.style, ["textAlign"])
        }}
      >
        {(content.uuid === "ecd3ac6c-af4d-4907-bdad-465ed276f049") && console.log("button", ref?.current?.getBoundingClientRect())}
        <div
          id={button_toolbar_id}
          className={clsx('button_container__toolbar', 
            (ref?.current?.getBoundingClientRect().y - toolbar_top_spacing_padding - toolbar_size_y < 0) && "button_container__toolbar__bottom", 
            (ref?.current?.getBoundingClientRect().x + toolbar_size_x - document?.getElementsByClassName("body_container")[0]?.clientWidth > 0) && "button_container__toolbar__right",
            content.class)}
        />

        <div
          className="button_container__inner"
          style={{
            ...pick(content.style, ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]),
          }}
        >

          <a
            className={clsx("button_container__inner__link", content.class)}
            style={{
              ...omit(content.style, ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]),
              textAlign: "center",
            }}
            selectable="true"
            href="#"
          >
            {/* <div className={"button__text"}> */}
            <ButtonTextEditor
              fixedElement={`#${button_toolbar_id}`}
              onChange={(value) => {
                content.text = value
              }}
              value={content.text}
            />
            {/* </div> */}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Canvas;
