import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import { useTranslation } from 'react-i18next';
import ColorPicker from '@views/editor/components/right-panel/style-tab/properties/color-picker/ColorPicker';


export const onChangeTextAlign = (component, value) => {
    if (value != 'none') {
        component.style = { ...component.style, textAlign: value };
    } else {
        component.style = { ...component.style, textAlign: '' };
    }
};

function Text({ colomn, component }): React.Node {
    
    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [textColor, setTextColor] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [linkColor, setLinkColor] = useState();

    const [spaceTop, setSpaceTop] = useState(null);
    const [spaceBottom, setSpaceBottom] = useState(null);
    const [spaceLeft, setSpaceLeft] = useState(null);
    const [spaceRight, setSpaceRight] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);


    useEffect(() => {
        init();
    }, []);

    const onChangeTextColor = (comp, value) => {
        console.log('onChangeTextColor', value)
        setTextColor(value.hex);
        selectedElement.style = {
            ...selectedElement.style,
            color: value
        }
        updatePage(page, true)
    };

    const onChangeLinkColor = (comp, value) => {
        setLinkColor(value.hex);
        selectedElement.attributes = {
            ...selectedElement.attributes,
            linkColor: value
        };
        updatePage(page, true);
    };

    const onChangeBackgroundColor = (comp, value) => {
        setBackgroundColor(value.hex);
        selectedElement.style = {
            ...selectedElement.style,
            backgroundColor: value.hex
        }
        updatePage(page, true)
    };

    const init = () => {

        setTextColor(selectedElement.style.color);
        setBackgroundColor(selectedElement.style.backgroundColor);
        setLinkColor(selectedElement?.attributes?.linkColor);

        setSpaceTop(selectedElement.style.paddingTop);
        setSpaceBottom(selectedElement.style.paddingBottom);
        setSpaceLeft(selectedElement.style.paddingLeft);
        setSpaceRight(selectedElement.style.paddingRight);

    };

    return (
        <React.Fragment>
            <PropertyWrapper
                label={t('globals.style-property')}>
                <div key={`text-color-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-12 row">
                    <div className="col-sm-12">
                        <label className="form-label" for="input-model">{t("right-panel.text.color-label")}</label>
                        <ColorPicker
                            color={textColor}
                            component={component}
                            onChange={onChangeTextColor}
                        />
                    </div>
                </div>
                <div key={`text-link-color-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-12 row">
                    <div className="col-sm-12">
                        <label className="form-label" for="input-model">{t("right-panel.text.link-color-label")}</label>
                        <ColorPicker
                            color={linkColor}
                            component={component}
                            onChange={onChangeLinkColor}
                        />
                    </div>
                </div>
            </PropertyWrapper>
            
            <PropertyWrapper
                label={t('globals.placement-property')}>
                <div key={`text-spacing-top-${selectedElement.uuid}`} class="mb-3 p-4  col-sm-6">
                    <Size label={"Top"} property={"paddingTop"} value={spaceTop} component={component} />
                </div>
                <div key={`text-spacing-bottom-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom"} property={"paddingBottom"} value={spaceBottom} component={component} />
                </div>
                <div key={`text-spacing-left-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Left"} property={"paddingLeft"} value={spaceLeft} component={component} />
                </div>
                <div key={`text-spacing-right-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Right"} property={"paddingRight"} value={spaceRight} component={component} />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div class="mb-3 p-4 col-sm-12">
                    <Hide
                        component={component}
                    />
                </div>
            </PropertyWrapper>
        </React.Fragment >
    );
}

export default Text;
