// contexts/ApplicationContext.jsx

import React, { createContext, useState, useContext } from 'react';

const ApplicationContext = createContext({});


export const ApplicationProvider = ({ children }) => {
  const [applicationId, setApplicationId] = useState(null);
  const [dynamicContents, setDynamicContents] = useState([]);
  const [isDynamicContent, setIsDynamicContent] = useState([]);
  const [metaData, setMetaData] = useState(null);

  const updateApplicationId = (value) => {
    setApplicationId(value);
  };
  
  
  const updateDynamicContents = (value) => {
    setDynamicContents(value);
  };

  const updateIsDynamicContent = (value) => {
    setIsDynamicContent(value);
  };

  const updateMetaData = (newMetaData) => {
    setMetaData(newMetaData);
  };


  return (
    <ApplicationContext.Provider
      value={{
        applicationId,
        updateApplicationId,
        metaData,
        updateMetaData,
        dynamicContents,
        updateDynamicContents,
        isDynamicContent,
        updateIsDynamicContent
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => useContext(ApplicationContext);
