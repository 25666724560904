import React, { useRef, useState } from 'react';
import * as ReactDOM from 'react-dom'
import _, { pick } from 'lodash'
import { Canvas as Colomn } from '../column';
import { useEditor } from '@contexts/EditorContext';
import clsx from 'clsx'
import { clone } from './utils';
import DragIndicator from '@views/editor/components/canvas/drag-indicator';
import OneColumn from '@views/editor/models/row/components/OneColumn';
import './index.scss'

function Row({ body, row, index }) {

  const rowContainer = useRef();

  const { dragElement, setDragElement, updatePage, updateSelectedTarget, selectedElement, updateSelectedElement, updateEditorAction, page, isDroppedElement, updateIsDroppedElement } = useEditor();

  const [dragPosition, setDragPosition] = useState(0);
  const [displayHighlight, setDisplayHighlight] = useState(false);

  const handelDrag = (ev) => {
    if (dragElement && dragElement.group === "block") {
      if (ev.type == "dragover") {
        // FIND DROG OVER TOP OF BOTTOM THE ELEMENT
        var element = ReactDOM.findDOMNode(rowContainer.current);
        var rect = element.getBoundingClientRect();
        const coordinates = [ev.pageX - rect.left, ev.pageY - rect.top];
        if (rect.height / 2 > coordinates[1]) {
          setDragPosition(1);
        } else {
          setDragPosition(2);
        }
      } else {
        setDragPosition(0);
      }
      ev.preventDefault();
    }
  };

  const handelDrop = (ev, index) => {
    if (dragElement.group === "block") {
      if (dragPosition === 1) {
        // PREPEND ELEMENT
        body.rows.splice(index, 0, clone(dragElement));
      } else if (dragPosition === 2) {
        // APPEND ELEMENT
        body.rows.splice(index + 1, 0, clone(dragElement));
      }
      updateIsDroppedElement(true);
      setDragPosition(4);
      updatePage(page, true);
    }
  };

  const select = (ev) => {
    updateSelectedElement(row)
    updateSelectedTarget(ev.target);
    updateEditorAction({ tab: "styles" });
    ev.stopPropagation();
  };

  const selectAdvance = (ev) => {
    updateSelectedElement(row)
    updateSelectedTarget(ev.target);
    updateEditorAction({ tab: "advanced" });
    ev.stopPropagation();
  };

  const handelClone = (ev) => {
    const newRow = clone(row);
    body.rows.splice(index, 0, newRow);
    updatePage(page, true);
  };

  const deleteRow = (ev) => {
    _.remove(body.rows, {
      uuid: row.uuid
    });
    if (body.rows.length === 0) {
      body.rows.splice(index, 0, OneColumn().defaultContent);
    }
    updatePage(page, true);
  };

  const handelMove = (ev) => {
    if (ev.type === "dragstart") {
      setDragElement(row);
    } else if (ev.type === "dragend") {
      if (isDroppedElement) {
        _.remove(body.rows, {
          uuid: dragElement.uuid
        });
        updateIsDroppedElement(false);
        setDragElement(null);
        setDragPosition(0);
        updatePage(page, true);
      }
    }
  };

  return (
    <React.Fragment>
      <div 
        key={rowContainer.uuid} 
        ref={rowContainer}
        className={clsx("row_container", (displayHighlight || selectedElement === row) && "hover")}
        style={{ ...pick(page.global.row.style, ["textAlign"]) }}
        onClick={select}
      >

        <div 
          className='row_container__sibling'
          onMouseEnter={() => setDisplayHighlight(true)}
          onMouseLeave={() => setDisplayHighlight(false)}
          onDragOver={handelDrag}
          onDragLeave={handelDrag}
          onDrop={(ev) => {
            handelDrop(ev, index)
          }}
        >
          <div class="row_highlight" style={{ display: (displayHighlight || selectedElement === row) ? "block" : "none" }}>
            <div>
              {(row.condition?.value) && (
                <a draggable className="highlight_icon" > <i class="la la-code" onClick={selectAdvance} ></i> </a>
              )}
              <a draggable className="highlight_icon" onDragStart={handelMove} onDragEnd={handelMove}> <i class="la la-arrows-alt"></i> </a>
              <a className="highlight_icon" onClick={handelClone}><i class="la la-copy"></i> </a>
              <a className="highlight_icon" onClick={deleteRow}> <i class="la la-trash"></i> </a>
            </div>
          </div>
        </div>
        <div class="row_content"
          style={{ ...pick(page.global.row.style, ["textAlign", "minWidth", "maxWidth"]) }}
          onDragOver={handelDrag}
          onDragLeave={handelDrag}
          onDrop={(ev) => {
            handelDrop(ev, index)
          }}>

          {(dragPosition === 1) && (
            <DragIndicator />
          )}

          <div style={{ ...page.global.row.style, ...row?.style }} class={row?.class} >
            {row?.colomns?.map((col) => {
              return (
                <Colomn page={page} colomn={col} contents={col.contents} style={col.style} className={col.class} />
              )
            })}
          </div>

          {(dragPosition === 2) && (
            <DragIndicator />
          )}
        </div>
      </div>
    </React.Fragment >
  );
}

export default Row;
