import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const name = 'Réseau Sociaux';
const image = '/libs/builder/icons/facebook.svg';

export default () => {
    return {
        type: 'social-media',
        group: 'content',
        name,
        image,
        controlContent: () => {
            return (
                <React.Fragment>
                    <div className='component_control'>
                        <img src={image} />
                        <div>
                            {name}
                        </div>
                    </div>
                </React.Fragment>
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": "content",
            "type": "social-media",
            "attributes": {
                "width": "25",
                "iconesSpacing": "5"
            },
            "style": {
                "textAlign": "center",
                "paddingTop": "10px",
                "paddingBottom": "10px",
                "paddingLeft": "0px",
                "paddingRight": "0px"
            },
            "socialMedia": [{
                "uuid": uuidv4(),
                "type": "instagram",
                "link": "#",
                "imgSrc": "https://s3-emailing.s3.eu-west-3.amazonaws.com/social-media/style-1/instagram.png"
            }, {
                "uuid": uuidv4(),
                "type": "facebook",
                "link": "#",
                "imgSrc": "https://s3-emailing.s3.eu-west-3.amazonaws.com/social-media/style-1/facebook.png"
            }]
        }
    };
};

