import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import "./Hide.scss";

const data = {
  options: [
    {
      value: 'none',
      icon: 'la la-times',
      //text: "None",
      title: 'None'
    },
    {
      value: 'mobile',
      //text: "Left",
      title: 'Mobile',
      icon: 'la la-mobile'
    },
    {
      value: 'desktop',
      //text: "Left",
      title: 'Desktop',
      icon: 'la la-desktop'
    },
  ]
}
function Hide({ t, val, component, onChange }): React.Node {
  const [value, setValue] = useState(null);
  const { page, updatePage } = useEditor();

  useEffect(() => {
      const _initHide = (component?.class && component?.class.includes("d-lg-none")) ? data.options[2].value : ((component?.class && component?.class.includes("d-sm-none")) ? data.options[1].value : data.options[0].value);
      setValue(_initHide);
  }, [val]);

  const handelOnChange = (ev) => {

    const _val = ev.target.value;
    setValue(_val);
    switch (_val) {
      case "desktop":
        if (!component.class || !component.class.includes("r-d-lg-none")) {
          component.class = `${(component.class) ? component.class : ""} r-d-lg-none`;
          component.class = component.class.replace(/r-d-sm-none/g, '');
        }
        break;
      case "mobile":
        if (!component.class || !component.class.includes("r-d-sm-none")) {
          component.class = `${(component.class) ? component.class : ""} r-d-sm-none`;
          component.class = component.class.replace(/r-d-lg-none/g, '');
        }
        break;
      default:
        if (component.class) {
          component.class = component.class.replace(/r-d-sm-none/g, '');
          component.class = component.class.replace(/r-d-lg-none/g, '');
        }
    }

    if (onChange) {
      onChange(component, ev.target.value);
    }
    updatePage(page, true);
  };

  return (
    <React.Fragment>
      <div className='hideproperty'>
        {data.options.map((option, i) => {
          return (
            <span className='hideproperty__device'>
              {/* ADD CHECKED VALUE */}
              {/* ADD EXTRA CLASS */}
              <input
                name={data.key}
                className="btn-check"
                type="radio"
                onChange={handelOnChange}
                value={option.value}
                id={`hide-${i}`}
                autocomplete="off"
                checked={option.value == value}
              />
              <label
                className="btn btn-outline-primary"
                for={`hide-${i}`}
                title={option.title}
              >
                <i className={option.icon}></i>
              </label>
            </span>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default Hide;
