import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EditorRouter from './editor/EditorRouter';
import LinksProvider from '@services/http/LinksProvider';
import ProtectedRoute from './editor/ProtectedRoute';
import PreviewRouter from './preview/PreviewRouter';

const PagesRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={LinksProvider.ROUTES.EDITOR} element={<EditorRouter />} /> */}
        <Route path={LinksProvider.ROUTES.SECURE_EDITOR} element={<ProtectedRoute  authorization={"EMAILING_PRO_TEMPLATE"} > <EditorRouter /></ProtectedRoute>} />
        <Route path={LinksProvider.ROUTES.PREVIEW} element={<PreviewRouter />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PagesRouter;
