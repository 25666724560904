import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const name = 'Text';
const image = '/libs/builder/icons/paragraph.svg';

export default () => {
    return {
        type: 'text',
        group: 'content',
        name,
        image,
        controlContent: () => {
            return (
                <React.Fragment>
                    <div className='component_control'>
                        <img src={image} />
                        <div>
                            {name}
                        </div>
                    </div>
                </React.Fragment>
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": "content",
            "type": "text",
            "style": {
                "paddingTop": "10px",
                "paddingBottom": "10px",
                "paddingLeft": "10px",
                "paddingRight": "10px"
            },
            "content": "<span>It a text bloc. <b>Ready for</b> being <i>modified</i> even with <span style=\"color:red;\">color</span></span>"
        }
    };
};

