import { Route } from "react-router-dom";
import React, { useEffect } from "react";
import LinksProvider from '@services/http/LinksProvider';
import KeycloakService from 'services/domain/KeycloakService';

function ProtectedRoute({ component: Component, authorization, children }) {
    const [isAuthLoaded, setAuthLoaded] = React.useState(false);
    const [isAuthorized, setAuthorized] = React.useState(false);

    useEffect(() => {
        KeycloakService.signinClient().then((data) => {
            if (authorization) {
                if (KeycloakService.hasRole(authorization)) {
                    setAuthorized(true);
                }
            } else {
                setAuthorized(true)
            }
            setAuthLoaded(true);
        });
    }, []);

    return (
        <React.Fragment>
            {(isAuthLoaded && isAuthorized) && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
            {/* {(isAuthLoaded && !isAuthorized) && (
                <React.Fragment>
                    Page 403 : Sorry!
                </React.Fragment>
            )} */}
        </React.Fragment>
    );
}

export default ProtectedRoute;
