import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import { filter, pull, without } from "lodash";

const data = {
  options: [
    {
      value: '',
      icon: 'la la-times',
      //text: "None",
      title: 'None',
      checked: true
    },
    {
      value: 'left',
      //text: "Left",
      title: 'Left',
      icon: 'la la-align-left',
      checked: false
    },
    {
      value: 'center',
      //text: "Center",
      title: 'Center',
      icon: 'la la-align-center',
      checked: false
    },
    {
      value: 'right',
      //text: "Right",
      title: 'Right',
      icon: 'la la-align-right',
      checked: false
    },
    {
      value: 'justify',
      //text: "justify",
      title: 'Justify',
      icon: 'la la-align-justify',
      checked: false
    }
  ]
}
function TextAlign({ t, val, component, onChange, hiddenOptions = [] }): React.Node {
  const [value, setValue] = useState(null);
  const { page, updatePage } = useEditor();

  useEffect(() => {
    setValue(val);
  }, [val]);

  const handelOnChange = (ev) => {
    setValue(ev.target.value);
    if (onChange) {
      onChange(component, ev.target.value);
    }
    updatePage(page, true);
  };

  return (
    <React.Fragment>
      {filter(data.options, option => !hiddenOptions.includes(option.value) )?.map((option, i) => {
        return (
          <React.Fragment>
            <input
              name={data.key}
              className="btn-check"
              type="radio"
              onChange={handelOnChange}
              value={option.value}
              id={`rb-38text-align${i}`}
              autocomplete="off"
              checked={option.value == value}
            />
            <label
              className="btn btn-outline-primary"
              for={`rb-38text-align${i}`}
              title={option.title}
            >
              <i className={option.icon}></i>
            </label>
          </React.Fragment>
        );
      })}
      <br />
    </React.Fragment>
  );
}

export default TextAlign;
