import React from 'react';
import ReactModal from 'react-modal';
import './index.scss';
import clsx from "clsx";

const Modal = ({ visible, hide, shouldCloseOnOverlayClick, title, style, className, children }) => {

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={hide}
      className={clsx("modal_content", className)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ content: style }}>
      {/* header */}
      <div className="modal_content__header">
        {title && <h4>{title}</h4>}
        <i className="la la-times close" onClick={hide} />
      </div>
      {/* content */}
      <div className="modal_content__body">
        {children}
      </div>
    </ReactModal>
  )
}

Modal.defaultProps = {
  visible: false,
  hide: () => undefined,
  title: undefined,
  shouldCloseOnOverlayClick: true,
  style: {},
  className: undefined
}

export default Modal;
