import React from 'react';
import "../index.scss";
import { v4 as uuidv4 } from 'uuid';


const name = 'Grid 3 col';

export default () => {
    return {
        type: 'row',
        group: 'block',
        image: '/libs/builder/icons/grid_row.svg',
        blockSize: "col-12",
        name,
        controlContent: () => {
            const elements = [1, 2, 3];
            return (
                <React.Fragment>
                    <div className='row row_control__wrapper'>
                        {elements.map((value, index) => {
                            return (
                                <div className='col-4 row_control__wrapper__col'>
                                    <div className='row row_control__wrapper__col__content' />
                                </div>
                            )
                        })}
                    </div>
                    <div className='row row_control__label'>
                        {name}
                    </div>
                </React.Fragment >
            )
        },
        defaultContent: {
            "uuid": uuidv4(),
            "group": 'block',
            "type": "row",
            "class": "row",
            "style": {
            },
            "colomns": [
                {
                    "uuid": uuidv4(),
                    "type": "three-columns",
                    "class": "col-4",
                    "attributes": {
                        "col": 4
                    },
                    "style": {
                    },
                    "contents": []
                },
                {
                    "uuid": uuidv4(),
                    "type": "three-columns",
                    "class": "col-4",
                    "attributes": {
                        "col": 4
                    },
                    "style": {
                    },
                    "contents": []
                },
                {
                    "uuid": uuidv4(),
                    "type": "three-columns",
                    "class": "col-4",
                    "attributes": {
                        "col": 4
                    },
                    "style": {
                    },
                    "contents": []
                }
            ]
        }
    };
};
