import React, { useEffect, useState, useRef } from 'react';
import { useEditor } from '@contexts/EditorContext';
import { ChromePicker } from 'react-color';
import './ColorPicker.scss';

const popover = {
  position: 'absolute',
  zIndex: '2',
  top: '50px',
  right: '0',
};

function ColorPicker({ t, component, color, onChange }): React.Node {
  const { page, updatePage } = useEditor();

  const [value, setValue] = useState(null);
  const [isVisible, setIsVisible] = useState(null);

  useEffect(() => {
    setValue(color || '');
  }, [color]);

  const handelOnChange = (value) => {
    setValue(value.hex || value);
    if (onChange) {
      onChange(component, value.hex);
    }
    updatePage(page, true);
  };

  const handelOnChangeInput = (ev) => {

    setValue(ev.target.value);
    if (onChange) {
      onChange(component, ev.target.value);
    }
    updatePage(page, true);
  };

  let colorPickerRef = useRef(null);
  let inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', () => handleClickOutside, true);
    };
  });

  return (
    <React.Fragment>
      <div className='color_picker' >
        <div onClick={() => setIsVisible(true)} className='color_picker__control' style={{ backgroundColor: value }}>

        </div>
        <input
          ref={inputRef}
          name={`color-picker-name-${component.uuid}`}
          type="text"
          className="form-control"
          value={value}
          onChange={handelOnChangeInput}
          onClick={() => setIsVisible(true)}
        />
        {isVisible && (
          <div style={popover} ref={colorPickerRef}>
            <ChromePicker
              disableAlpha={true}
              color={value}
              onChange={handelOnChange}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ColorPicker;
