import { v4 as uuidv4 } from 'uuid';

export const clone = (row) => {
    // SET NEW UUID
    const newRow = { ..._.cloneDeep(row), uuid: uuidv4() };
    newRow.colomns.forEach((col) => {
        col.uuid = uuidv4();
        col.contents.forEach((content) => {
            content.uuid = uuidv4();
        });
    });
    return newRow;
}

export default {};
