import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import TextAlign from '@views/editor/components/right-panel/style-tab/properties/text-align/TextAlign';
import PropertyWrapper from '@views/editor/components/right-panel/style-tab/wrapper/PropertyWrapper';
import FormRange from '@views/editor/components/right-panel/style-tab/properties/form-range/FormRange';
import ColorPicker from '@views/editor/components/right-panel/style-tab/properties/color-picker/ColorPicker';
import Hide from '@views/editor/components/right-panel/style-tab/properties/hide/Hide';
import ColResizer from './ColResizer';
import { useTranslation } from 'react-i18next';
import Size from '@views/editor/components/right-panel/style-tab/properties/size/Size';

function Row({ component }): React.Node {

    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [backgroundColor, setBackgroundColor] = useState(null);
    const [stack, setStack] = useState(null);


    const [borderColor, setBorderColor] = useState(null);

    const [borderWidth, setBorderWidth] = useState(null);
    const [borderStyle, setBorderStyle] = useState(null);


    useEffect(() => {
        init();
    }, [selectedElement]);

    useEffect(() => {
        init();
    }, []);


    const init = () => {
        setBackgroundColor(selectedElement.style?.backgroundColor);
        setStack(((selectedElement?.colomns[0]?.class?.includes("col-lg")) ? true : false));
        setBorderColor(selectedElement.style.borderColor);
        setBorderWidth(selectedElement.style.borderWidth);
        setBorderStyle(selectedElement.style.borderStyle);
    };

    const onChangeBackgroundColor = (component, value) => {
        let _val = (value) ? value : undefined; // <=== default value
        selectedElement.style = { ...selectedElement.style, backgroundColor: _val };
        setBackgroundColor(_val)
    };

    const handelOnChangeStack = (ev) => {
        let _val = (ev.target.value === "true");
        setStack(_val);
        for (let i = 0; i < selectedElement.colomns.length; i++) {
            if (_val) {
                selectedElement.colomns[i].class = selectedElement.colomns[i].class.replace(/col-[0-9]|col-lg-[0-9]/g, `col-lg-${selectedElement.colomns[i].attributes.col}`);
            } else {
                selectedElement.colomns[i].class = selectedElement.colomns[i].class.replace(/col-[0-9]|col-lg-[0-9]/g, `col-${selectedElement.colomns[i].attributes.col}`);
            }
        }
    };

    const onChangeBorderColor = (comp, value) => {
        let _val = (value) ? value : "#fff"; // <=== default value
        setBorderColor(value);
        selectedElement.style = {
            ...selectedElement.style,
            borderColor: _val
        }
        updatePage(page, true)
    };

    return (
        <React.Fragment>
            <PropertyWrapper
                label={t('globals.color-property')}>
                <div key={`row-bg-color-${selectedElement.uuid}`} class="mb-12  col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.row.background-color-label')} </label><br />
                    <ColorPicker
                        color={backgroundColor}
                        component={component}
                        onChange={onChangeBackgroundColor}
                    />
                </div>
            </PropertyWrapper>

{console.log('Row***', selectedElement)}
            <PropertyWrapper
                label={t('globals.style-label')}>
                <label class="form-label" for="input-model">Border radius</label>
                <div key={`text-spacing-top-${selectedElement.uuid}`} class="mb-3 p-4  col-sm-6">
                    <Size label={"Top Left"} property={"borderTopLeftRadius"} value={component.style?.borderTopLeftRadius} component={component} />
                </div>
                <div key={`text-spacing-bottom-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom Left"} property={"borderBottomLeftRadius"} value={component.style?.borderBottomLeftRadius} component={component} />
                </div>
                <div key={`text-spacing-left-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Top Right"} property={"borderTopRightRadius"} value={component.style?.borderTopRightRadius} component={component} />
                </div>
                <div key={`text-spacing-right-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-6">
                    <Size label={"Bottom Right"} property={"borderBottomRightRadius"} value={component.style?.borderBottomRightRadius}  component={component} />
                </div>



                <label class=" form-label" for="input-model">Border</label>
                {/* <div key={`button-border-width-${selectedElement.uuid}`} className="mb-3 p-4  col-sm-6">
                    <Size label={t('right-panel.button.border-width-label')} property={"borderWidth"} value={borderWidth} component={component} />
                </div> */}
                <div class="mb-3 p-4  col-sm-6">
                    <label className="form-label" for="input-model">{t('right-panel.button.border-color-label')}</label>
                    <ColorPicker
                        color={borderColor}
                        component={component}
                        onChange={onChangeBorderColor}
                    />
                </div>

                <div key={`button-border-style-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('right-panel.button.border-style-label')}</label>
                    <select class="form-select small-arrow" name="borderStyle" value={borderStyle}
                        onChange={(ev) => {
                            setBorderStyle(ev.target.value);
                            component.style = {
                                ...component.style,
                                borderStyle: ev.target.value
                            };
                            updatePage(page, true);
                        }}>
                        <option value="none">none</option>
                        <option value="solid">solid</option>
                        <option value="dashed">dashed</option>
                    </select>
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('globals.hide-property')}>
                <div key={`row-hide-property-${selectedElement.uuid}`} class="mb-3 p-4 col-sm-12">
                    <label class=" form-label" for="input-model">{t('globals.hide-device-label')}</label><br />
                    <Hide
                        component={component}
                    />
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('right-panel.row.stack-columns-property')}>
                <div key={`row-stack-${selectedElement.uuid}`} className="mb-3 p-4 col-sm-12">
                    <label className="form-label" for="input-model">{t('right-panel.row.stack-columns-label')} : </label><br />
                    <div className="text-center">
                        <span className='p-5'>
                            <input
                                name={"stack"}
                                className="btn-check"
                                type="radio"
                                onChange={handelOnChangeStack}
                                value={false}
                                id={`stack-no`}
                                autocomplete="off"
                                checked={false == stack}
                            />
                            <label
                                className="btn btn-outline-primary"
                                for={`stack-no`}
                                title={"No stack"}
                            >
                                <i className={"la la-times"}></i>
                            </label>
                        </span>
                        <span className='p-5'>
                            <input
                                name={"stack"}
                                className="btn-check"
                                type="radio"
                                onChange={handelOnChangeStack}
                                value={true}
                                id={`hide-yes`}
                                autocomplete="off"
                                checked={true == stack}
                            />
                            <label
                                className="btn btn-outline-primary"
                                for={`hide-yes`}
                                title={"Stack"}
                            >
                                <i className="la la-layer-group"></i>
                            </label>
                        </span>
                    </div>
                </div>
            </PropertyWrapper>

            <PropertyWrapper
                label={t('right-panel.row.column-size-property')}>
                <div
                    key={`row-col-size-${selectedElement.uuid}`}
                    className="mb-3 p-4 col-sm-12">
                    <ColResizer cols={selectedElement.colomns} />
                </div>
            </PropertyWrapper>
        </React.Fragment >
    );
}

export default Row;
