import React, { useState, useEffect } from 'react';
import _ from "lodash";
import Split from 'react-split';

import "./index.scss"
import { useEditor } from '@contexts/EditorContext';

function ColResizer({ cols }) {

  const [key, setKey] = useState(0);
  const [sizes, setSizes] = useState(null);
  const [initSizes, setInitSizes] = useState(null);

  const { page, updatePage } = useEditor();


  useEffect(() => {
    calculateSizes()
  }, [cols]);

  const calculateSizes = () => {
    let _sizes = [];
    let _initSizes = [];
    cols?.map((col) => {
      _sizes.push(col.attributes.col);
      _initSizes.push(col.attributes.col / 12 * 100);
    });
    setKey(key + 1);
    setSizes(_sizes)
    setInitSizes(_initSizes);
  }

  const handelOnChange = (sizes) => {
    let _sizes = [];
    for (let i = 0; i < sizes.length - 1; i++) {
      _sizes.push(Math.round(sizes[i] * 12 / 100))
    }
    _sizes.push(12 - _.sum(_sizes))
    setSizes(_sizes);

    for (let i = 0; i < cols.length; i++) {
      cols[i].attributes.col = _sizes[i];
      cols[i].class = cols[i].class.replace(/(col-?l?g?-)\d{1,2}/gm, `$1${_sizes[i]}`);
    }

    
    calculateSizes()
    updatePage(page, true);

  };

  return (
    <React.Fragment >
      {(sizes) && (
        <Split key={key}
          sizes={initSizes}
          className="colresizer"
          minSize={20}
          // expandToMin={false}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={10}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          onDragEnd={handelOnChange}
        >
          {sizes.map((size, index) => {
            return (
              <div className='colresizer__col' >
                <React.Fragment>
                  <div className='colresizer__col__content'>
                    <div>
                      <strong>
                        {index + 1}
                      </strong>
                    </div>
                    <div>
                      {Math.round(size * 100 / 12)}%
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )
          })}
        </Split>
      )
      }
    </React.Fragment >
  );
}

export default ColResizer;
