import React, { useRef } from 'react';
import clsx from 'clsx';
import './index.scss';
import _ from 'lodash';
import parser from 'react-html-parser';
import { useApplication } from '@contexts/ApplicationContext';

function DynamicContent({ content }) {
  const ref = useRef();
  const { dynamicContents, metaData } = useApplication();

  return (
    <div
      key={content.uuid}
      ref={ref}
      className={clsx('dc_container', content.class)}
      style={{
        ..._.pick(content.style, [
          'maxWidth'
          // 'textAlign',
          // 'paddingTop',
          // 'paddingBottom',
          // 'paddingLeft',
          // 'paddingRight',
        ])
      }}>
      {parser(
        dynamicContents
          .find((dc) => dc.id === content.contentId)
          ?.template_content?.find((tc) => tc.id === content.html)?.html_content
      )}
    </div>
  );
}

export default DynamicContent;
